import React, {useCallback, useMemo, useState } from 'react'
import {useDropzone} from 'react-dropzone'
import axios from 'axios';
import {
    Spinner, Container, Row, Col, Form, FormControl, InputGroup, Button, Nav, Navbar, NavDropdown, Card
} from 'react-bootstrap';
import { Redirect } from "react-router-dom";
import { Link } from 'react-router-dom';
import './Upload.css';

const baseStyle = {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '20px',
    borderWidth: 2,
    borderRadius: 2,
    borderColor: '#eeeeee',
    borderStyle: 'dashed',
    backgroundColor: '#fafafa',
    color: '#bdbdbd',
    outline: 'none',
    transition: 'border .24s ease-in-out',
    marginTop: '20px',
    minHeight: '50vh',
};

const activeStyle = {
    borderColor: '#2196f3'
};

const acceptStyle = {
    borderColor: '#00e676'
};

const rejectStyle = {
    borderColor: '#ff1744'
};

const Upload = () => {

    const [userMessage, setUserMessage] = useState();
    const [showQueueButton, setShowQueueButton] = useState(false);
    const [showUserMessage, setShowUserMessage] = useState(false);
    const [toRecords, setToRecords] = useState(false);

    const uploadFile = (file) => {
        var formData = new FormData();
        formData.append("file", file);
        //formData.append("project_id",this.state.project.id);
        let _self = this;
        setUserMessage("Uploading...");
        setShowUserMessage(true)
        axios.post("api/calling/patient/upload", formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }).then( function(response) {
            if (response.data.status == 'success') {
                setUserMessage("Upload complete, forwarding to Records page...");
                setTimeout(() => {
                    setToRecords(true);
                }, 5000);
                //setShowQueueButton(true);
            } else {
                setUserMessage("File was Uploaded but there was an error during processing.")
            }
        });
    }

    const onDrop = useCallback((acceptedFiles) => {
        acceptedFiles.forEach((file) => {
            uploadFile(file);
        })
    }, [])




    const {
        acceptedFiles,
        getRootProps,
        getInputProps,
        isDragActive,
        isDragAccept,
        isDragReject
    } = useDropzone({onDrop});


    const style = useMemo(() => ({
        ...baseStyle,
        ...(isDragActive ? activeStyle : {}),
        ...(isDragAccept ? acceptStyle : {}),
        ...(isDragReject ? rejectStyle : {})
    }), [
        isDragActive,
        isDragReject
    ]);

    return (
        <>
        {toRecords ? <Redirect to="/records" /> : null}
            <div className="container">
            <Row className="fileUploadRow">
                <Col>
                    <div  {...getRootProps({style})}>
                        <input {...getInputProps()} />
                        <span  className="fileUploadRow">Drag and Drop a File here,<br />or Click here to upload a file</span>
                    </div>

                { showUserMessage ? <div className="font-weight-bold text-center mt-2">
                    <Spinner animation="border" /><p> { userMessage }</p>
                </div> : null }

                    { showQueueButton ? <div className="font-weight-bold text-center">
                        <Link to="/queues">
                        <button className="btn btn-success" >Manage Queues</button>
                    </Link>
                    </div> : null }
                </Col>
            </Row>
            </div>
        </>
);
}


export default Upload;