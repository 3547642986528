import React, { useState } from 'react';
import axios from 'axios';
import {
    Container, Row, Col, Form, FormControl, InputGroup, Button, Nav, Navbar, NavDropdown, Card
} from 'react-bootstrap';
import { Redirect } from "react-router-dom";

const Logout = (props) => {

    const [redirect, setRedirect] = useState(null);
    const [toHome, setToHome] = useState(false);

    const logout = () => {
        axios.post('logout')
            .then(res => {
                window.token = res.data;
                props.setUser(null);
                setToHome(true);
            });
    }

    logout();

    return (
        <>
        {toHome ? <Redirect to="/login" /> : null}
        </>
    )
}

export default Logout;