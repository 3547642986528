import React, { useState } from 'react';
import {
  Container, Row, Col, Form, FormControl, InputGroup, Button, Nav, Navbar, NavDropdown, Card
} from 'react-bootstrap';
import axios from 'axios';
import { Redirect } from "react-router-dom";


const PasswordReset = (props) => {

  const [current_password, setCurrentPassword] = useState();
  const [password, setPassword] = useState();
  const [email, setEmail] = useState();
  const [password_confirmation, setPasswordConfirmation] = useState();
  const [resetPasswordMessage, setResetPasswordMessage] = useState();
  const [currentUser, setCurrentUser] = useState();



  const passwordReset = (e) => {

    e.preventDefault();
    let data = {
      email: email,
      password: password,
      password_confirmation: password_confirmation,
    }
    axios.post('password/reset', data)
        .then(res => {
          window.token = res.data;
          if (res.data.status) {
            setResetPasswordMessage(res.data.status);
          }
          if (res.data.message) {
            setResetPasswordMessage(res.data.message);
          }
        })
        .catch((err) => {
          if (err.response.data.errors.password_confirmation) {
            setResetPasswordMessage(err.response.data.errors.password_confirmation[0]);
          }
          console.log(err.response);
        })
  }

  return (

      <Container>
      <Row>
      <Col>
      <Card className="content-card">
      <Card.Header>Reset Password</Card.Header>
      <Card.Body>

  <Form onSubmit={(e) => passwordReset(e)}>
<Form.Group as={Row} controlId="formBasicEmail">
      <Form.Label column sm="4">Email Address</Form.Label>
  <Col sm="8">
      <Form.Control type="email" placeholder="" onChange={(e) => setEmail(e.target.value)} />
  </Col>
  </Form.Group>
  <Form.Group as={Row} controlId="formBasicPassword">
      <Form.Label column sm="4">Password</Form.Label>
      <Col sm="8">
      <Form.Control type="password" placeholder="" onChange={(e) => setPassword(e.target.value)} />
  </Col>
  </Form.Group>
  <Form.Group as={Row} controlId="formBasicPasswordConfirmation">
      <Form.Label column sm="4">Password Confirmation</Form.Label>
  <Col sm="8">
      <Form.Control type="password" placeholder="" onChange={(e) => setPasswordConfirmation(e.target.value)} />
  </Col>
  </Form.Group>
  <Button variant="primary" type="submit" >
      Reset Password
  </Button>
  </Form>
  <div id="ResetPasswordMessage" style={{marginTop: 10 + 'px'}} >{ resetPasswordMessage }</div>

  </Card.Body>
  </Card>
  </Col>
  </Row>
  </Container>

)
}


export default PasswordReset;