import React, { useState } from 'react'
import {
    Container, Row, Col, Form, FormControl, InputGroup, Button, Nav, Navbar, NavDropdown, Card, Modal
} from 'react-bootstrap';
import axios from 'axios';
import { useTable } from 'react-table'
import styled from 'styled-components'
import moment from 'moment'
import './ViewLog.css';


const Styles = styled.div`
  padding: 1rem;

  table {
    border-spacing: 0;
    border: 1px solid black;

    tr {
      :last-child {
        td {
          border-bottom: 0;
        }
      }
    }

    th,
    td {
      margin: 0;
      padding: 0.5rem;
      border-bottom: 1px solid black;
      border-right: 1px solid black;

      :last-child {
        border-right: 0;
      }
    }
  }
`


const ViewLog = props => {

    const [logData, setLogData] = useState(null);
    const [dataLoading, setDataLoading] = React.useState(false)
    const [dataLoaded, setDataLoaded] = React.useState(false)
    const [users, setUsers] = React.useState(false)

    if (!logData) {
        axios.get('api/ticketing/ticket/' + props.ticketID + '?with=procedure_task,logs,logs.ticket_action,logs.user,logs.ticket_queue')
            .then(res => {
                props.toggleLoading(false);
                setLogData(res.data.logs);
            })
    }

    /*if(props.user && !users) {
        axios.get('api/admin/user')
            .then(res => {
                let mappedUsers = [];
                res.data.forEach((user) => {
                    mappedUsers[user.id] = user;
                })
                setUsers(mappedUsers)
            })
    }*/


    const columns = React.useMemo(
        () => [
            {
                Header: 'Date',
                minWidth: 500,
                accessor: d => {
                    if (d.created_at) {
                        return moment(d.created_at)
                            .local()
                            .format("MM/DD/YYYY HH:mm:ss")
                    } else {
                        return "None Recorded"
                    }
                }
             }
            ,
            {
                Header: 'User',
                accessor: 'user.name',
            },
            {
                Header: 'Queue',
                accessor: '',
                accessor: d => {
                    if (d.user) {
                        return <div style={{ textAlign: "center" }}>{(d.user.ticket_queue) ? d.user.ticket_queue : "-"}</div>;
                    } else {
                        return <div style={{ textAlign: "center" }}>-</div>;
                    }

                }
            },
            {
                Header: 'Action',
                accessor: d => {
                    if (d.user) {
                        return <div style={{ textAlign: "center" }}>{(d.user.ticket_action) ? d.user.ticket_action : "-"}</div>;
                    } else {
                        return <div style={{ textAlign: "center" }}>-</div>;
                    }

                }
            },
            {
                Header: 'Note',
                accessor: 'note',
            },
        ],
        [ logData ]
    )


    function Table({ columns, data }) {
        // Use the state and functions returned from useTable to build your UI
        const {
            getTableProps,
            getTableBodyProps,
            headerGroups,
            rows,
            prepareRow,
        } = useTable({
            columns,
            data,
        })
        // Render the UI for your table
        return (
            <table style={{width: '100%'}} {...getTableProps()}>
                <thead>
                {headerGroups.map(headerGroup => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map(column => (
                            <th {...column.getHeaderProps()}>{column.render('Header')}</th>
                        ))}
                    </tr>
                ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                {rows.map(
                    (row, i) => {
                        prepareRow(row);
                        return (
                            <tr {...row.getRowProps()}>
                                {row.cells.map(cell => {
                                    return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                                })}
                            </tr>
                        )}
                )}
                </tbody>
            </table>
        )
    }

    if (!logData) {
        return ( "loading..." )
    } else {
        return (
            <>
                <div className="flex-large" >
                        <Modal.Header closeButton>
                            <Modal.Title>Ticket Log</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Styles>
                                <Table columns={columns} data={logData} />
                            </Styles>
                        </Modal.Body>
                        <Modal.Footer>
                        </Modal.Footer>
                </div>
            </>
        )
    }
}

export default ViewLog