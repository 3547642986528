import React, { useState } from 'react';
import axios from 'axios';
import styled from 'styled-components'
import {
    Container, Row, Col, Button, Card, Modal
} from 'react-bootstrap';
import { useTable } from 'react-table'
import SweetAlert from 'react-bootstrap-sweetalert';
import * as Icon from 'react-bootstrap-icons';
import AddQueueForm from './AddQueueForm'
import EditQueueForm from './EditQueueForm'
import ReactTable from 'react-table-6'
import 'react-table-6/react-table.css'
import './Queues.css';


const Styles = styled.div`
  padding: 1rem;

  table {
    border-spacing: 0;
    border: 1px solid black;

    tr {
      :last-child {
        td {
          border-bottom: 0;
        }
      }
    }

    th,
    td {
      margin: 0;
      padding: 0.5rem;
      border-bottom: 1px solid black;
      border-right: 1px solid black;

      :last-child {
        border-right: 0;
      }
    }
  }
`


const Queue = (props) => {

    const [data, setData] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [editing, setEditing] = useState(false)
    const initialFormState = { id: null, name: '', type: '' }
    const [queues, setQueues] = useState(null);
    const [currentQueue, setCurrentQueue] = useState(initialFormState);
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [editShow, setEditShow] = useState(false);
    const handleEditClose = () => setEditShow(false);
    const handleEditShow = () => setEditShow(true);
    const [alert, setAlert] = useState(null);

    if (!data  && props.user) {
        props.toggleLoading(true);
        axios.get('api/ticketing/queue?per_page=100')
            .then(res => {
                let mappedQueues = [];
                res.data.data.forEach((queue) => {
                    mappedQueues[queue.id] = queue;
                })
                setQueues(mappedQueues)
                props.toggleLoading(false);
                setData(res.data.data);
            })
    }

    function hideAlert() {
        setAlert(null)
    }

    function handleEdit(row) {
        //setEditing(true)
        //setCurrentQueue({ id: row.original.id, name: row.original.name,  type: row.original.ticket_queue_type_id })
        //handleEditShow()
    }

    const updateQueue = (id, updatedQueue) => {
        setEditing(false)
        let data = {
            id: id,
            name: updatedQueue.name,
        }
        axios.put('api/ticketing/queue/'+id, data)
            .then(res => {
                axios.get('api/ticketing/queue?per_page=100')
                    .then(res => {
                        setShow(false);
                        setData(null);
                        setData(res.data.data);
                    })
            });
    }


    // CRUD operations
    const addQueue = queues => {
        let data = {
            name: queues.name,
            type: queues.type,
        }
        axios.post('api/ticketing/queue', data)
            .then(res => {
                axios.get('api/ticketing/queue?per_page=100')
                    .then(res => {
                        setShow(false);
                        setData(null);
                        setData(res.data.data);
                    })
            });


    }
    /*
        const deleteQueue = id => {
            setEditing(false)
            setQueues(queues.filter(queues => queues.id !== id))
        }



      */

    function deleteConfirmation(row)  {
        const getAlert = (row) => ( <SweetAlert
                warning
                showCancel
                confirmBtnText="Yes, delete this queue!"
                confirmBtnBsStyle="danger"
                title="Are you sure?"
                onCancel={()=>hideAlert()}
                onConfirm={()=>handleDelete(row.id)}
            >
                Are you sure you want to delete {row.name}?
            </SweetAlert>
        );

        setAlert(getAlert(row.original));

    }

    function handleDelete(id) {
        axios.delete('api/ticketing/queue/' +  id)
            .then(res => {
                axios.get('api/ticketing/queue?per_page=100')
                    .then(res => {
                        setData(null);
                        setData(res.data.data);
                        setAlert(null);
                    })
            })
            .catch((err) => {
                console.log(err);
            })
    }

    const columns = React.useMemo(
        () => [
            {
                Header: '',
                id: 1,
                resizable: false,
                Cell: row => (
                    <div>
                    <Icon.Pencil color="royalblue" size={32} onClick={() => handleEdit(row)} />
    <Icon.XCircle color="red" size={32} onClick={() => deleteConfirmation(row)} />
    </div>
)}, {
            Header: 'Name',
            accessor: 'name',
        },
            {
                Header: 'Type',
                id: 'type',
                accessor: d => {
                    if (d.ticket_queue_type_id) {
                        return queues[d.ticket_queue_type_id].name;
                    } else {
                        return "Unassigned";
                    }
                },
            },
    {
        Header: 'Number of Tickets',
            accessor: 'ticket_counts',
    },/*{
        Header: 'Users',
            accessor: 'name',
    },*/
        ],
        [queues, data]
    )


    function Table({ columns, data }) {
        // Use the state and functions returned from useTable to build your UI
        const {
            getTableProps,
            getTableBodyProps,
            headerGroups,
            rows,
            prepareRow,
        } = useTable({
            columns,
            data,
        })
        // Render the UI for your table
        return (
            <table style={{width: '100%'}} {...getTableProps()}>
    <thead>
        {headerGroups.map(headerGroup => (
                <tr {...headerGroup.getHeaderGroupProps()}>
        {headerGroup.headers.map(column => (
            <th {...column.getHeaderProps()}>{column.render('Header')}</th>
        ))}
    </tr>
    ))}
    </thead>
        <tbody {...getTableBodyProps()}>
        {rows.map(
            (row, i) => {
                prepareRow(row);
                return (
                    <tr {...row.getRowProps()}>
                {row.cells.map(cell => {
                    return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                })}
            </tr>
            )}
        )}
    </tbody>
        </table>
    )
    }
    if (!data || !queues) {
        return (
            <div></div>
    );
    } else {
        return (
            <Container>
            <Row>
            <Col>
            <Card className="queues-card content-card">
            <Card.Header>Queues</Card.Header>
            <Card.Body>
            <Row><Col className="text-right">
            <Button className={['btn-success']} variant="primary" onClick={handleShow}  style={{marginRight: '15px', marginBottom: '10px'}}>
        Add a Queue
        </Button>
        </Col></Row>
        {/*<Styles>
        <Table columns={columns} data={data} />
        </Styles>*/}
        <ReactTable
            data={data}
            columns={columns}
            defaultPageSize={10}
        />
        <Modal show={show} onHide={handleClose}>
            <AddQueueForm addQueue={addQueue}  {...props} />
        </Modal>
        <Modal show={editShow} onHide={handleEditClose}>
            <EditQueueForm currentQueue={currentQueue} updateQueue={updateQueue} setEditing={setEditing} {...props} />
        </Modal>
        </Card.Body>
        </Card>
        </Col>
        </Row>
          {alert}
        </Container>

    );
    }
}

export default Queue;