import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import axios from 'axios';

axios.defaults.baseURL = process.env.REACT_APP_API_URL;
axios.defaults.responseType = 'json';
axios.defaults.withCredentials = true;
axios.defaults.headers = {'Accept': 'application/json'};
window.token = '';

// Add a request interceptor
axios.interceptors.request.use(function (config) {
    // const token = store.getState().session.token;
    config.headers.Authorization =  'Bearer '+window.token;
    return config;
});

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
