import React, { useState, useEffect } from 'react'
import {
    Container, Row, Col, Form, FormControl, InputGroup, Button, Nav, Navbar, NavDropdown, Card, Modal
} from 'react-bootstrap';
import axios from 'axios';

const EditUserForm = props => {
    const [ user, setUser ] = useState(props.currentUser)
    const initialRoleValue = [{ id: 0, value: " Select A Role " }];
    const [roles, setRoles] = useState(initialRoleValue);

    useEffect(
        () => {
            setUser(props.currentUser)
        },
        [ props ]
    )
    // You can tell React to skip applying an effect if certain values haven’t changed between re-renders. [ props ]

    const handleInputChange = event => {
        const { name, value } = event.target

        setUser({ ...user, [name]: value })
    }

    let roleComponent = null;

    // useEffect to get Queues
    useEffect(() => {
        axios('api/role').then(res => {
            setRoles(res.data)
            }
        );
    }, []);

    roleComponent = (
        /*<select onChange={onQueueTypeSelection} >*/
        <select name="role" id="role" onChange={handleInputChange} value={user.role_id}>
        <option key="0" value="0"> Select A Role </option>
    {roles.map((role, index) => {
        return <option key = {index} value = {role.id} > {role.name} < /option>
    })}
</select>
);
            


    return (
        <div>
        <form
    onSubmit={event => {
        event.preventDefault()
        props.updateUser(user.id, user)
    }}
>
<Modal.Header closeButton>
    <Modal.Title>Edit User</Modal.Title>
    </Modal.Header>
    <Modal.Body>
    <div className="flex-large">
    <Row>
    <Form.Label column sm="4" className="text-right">Name</Form.Label>
        <Col sm="8" className="text-left"><input type="text" name="name" value={user.name} onChange={handleInputChange} /></Col>
    </Row>
    <Row>
    <Form.Label column sm="4" className="text-right">Email</Form.Label>
        <Col sm="8" className="text-left"><input type="text" name="email" value={user.email} onChange={handleInputChange} /></Col>
    </Row>
    <Row>
    <Form.Label column sm="4" className="text-right">Role</Form.Label>
        <Col sm="8" className="text-left">
        {roleComponent}
    </Col>
    </Row>
        </div>
        </Modal.Body>
        <Modal.Footer>
        <button  className="btn btn-success">Update user</button>
    <button className="btn btn-danger" onClick={() => props.handleEditClose()} >Cancel</button>
    </Modal.Footer>
    </form>
    </div>

)
}

export default EditUserForm