import React, { useState, useEffect } from 'react'
import axios from 'axios';
import {
    Container, Row, Col, Form, FormControl, InputGroup, Button, Nav, Navbar, NavDropdown, Card, Modal
} from 'react-bootstrap';

const AddUserForm = props => {

    const [ user, setUser ] = useState(null)
    const [data, setData] = useState(null);
    const initialRoleValue = [{ id: 0, value: " Select A Role " }];
    const [roles, setRoles] = useState(initialRoleValue);

    const handleInputChange = event => {
        const { name, value } = event.target
        setUser({ ...user, [name]: value })
    }

    if (!data) {
        axios.get('api/role')
            .then((res) => {
                return res.data;
            })
    }

    let roleComponent = null;

    // useEffect to get Queues
    useEffect(() => {
        axios('api/role').then(res => {
                setRoles(res.data)
            }
        );
    }, []);

    roleComponent = (
        /*<select onChange={onQueueTypeSelection} >*/
        <select name="role" id="role" onChange={handleInputChange}>
        <option key="0" value="0"> Select A Role </option>
    {roles.map((role, index) => {
        return <option key = {index} value = {role.id} > {role.name} < /option>
    })}
</select>
);



    return (
        <form
            onSubmit={event => {
                event.preventDefault()
                if (!user.name || !user.email) return
                props.addUser(user)
            }}
        >
<Modal.Header closeButton>
    <Modal.Title>Add a User</Modal.Title>
    </Modal.Header>
    <Modal.Body>
    <div className="flex-large">

<Row>
    <Form.Label column sm="4" className="text-right">User Name</Form.Label>
    <Col sm="8" className="text-left"><input type="text" name="name" onChange={handleInputChange} /></Col>
    </Row>
    <Row>
        <Form.Label column sm="4" className="text-right">Email Address</Form.Label>
        <Col sm="8" className="text-left"><input type="text" name="email" onChange={handleInputChange} /></Col>
    </Row>
    <Row>
        <Form.Label column sm="4" className="text-right">Role</Form.Label>
        <Col sm="8" className="text-left">
        {roleComponent}
        </Col>
    </Row>
    </div>
    </Modal.Body>
        <Modal.Footer>
    <button  className="btn btn-success">Add new user</button>
    </Modal.Footer>
    </form>
)
}

export default AddUserForm