import React, { useState, useEffect } from 'react'
import {
    Container, Row, Col, Form, FormControl, InputGroup, Button, Nav, Navbar, NavDropdown, Card, Modal
} from 'react-bootstrap';
import axios from 'axios';
import { useTable } from 'react-table'
import ReactTable  from 'react-table-6'
import styled from 'styled-components'
import moment from 'moment'
import './ViewUserLog.css';
import "imrc-datetime-picker/dist/imrc-datetime-picker.css";
import {DatetimePickerTrigger} from 'imrc-datetime-picker';



const ViewUserLog = props => {

    const [userLogData, setUserLogData] = useState([]);
    const [tableData, setTableData] = useState([]);
    const [dataLoading, setDataLoading] = React.useState(false)
    const [dataLoaded, setDataLoaded] = React.useState(false)
    const [users, setUsers] = React.useState([])
    const [currentUser, setCurrentUser] = React.useState(false)
    const [startDate, setStartDate] = React.useState(moment().subtract(7,'d'))
    const [endDate, setEndDate] = React.useState(moment())
    const [initialMoment, setInitialMoment] = React.useState(moment())
    const [searchInput, setSearchInput] = useState(0);

    function loadUserLogData () {
        if (!currentUser) {
            alert("Please select a User");
        } else {
            let formattedStartDate = startDate.format('YYYY-MM-DD');
            let formattedEndDate = endDate.format('YYYY-MM-DD');

            if (currentUser==0) {
                axios.get('api/ticketing/ticket/userrolelogs/3?start_date='+formattedStartDate+'&end_date='+formattedEndDate)
                    .then(res => {
                        props.toggleLoading(false);
                        setUserLogData([]);
                        setUserLogData(res.data);
                        setTableData(res.data);
                    })
            } else {
                axios.get('api/ticketing/ticket/userlogs/'+currentUser+'?start_date='+formattedStartDate+'&end_date='+formattedEndDate)
                    .then(res => {
                        props.toggleLoading(false);
                        setUserLogData([]);
                        setUserLogData(res.data);
                        setTableData(res.data);
                    })
            }

        }
    }

    if(users.length==0) {
        axios.get('api/admin/user')
            .then(res => {
                setUsers(res.data)
            })
    }

    var stylesHeaders = {
        margin: '10px',
        float: 'left',
        fontWeight: 'bold',
    };

    var stylesContent = {
        margin: '10px',
        float: 'left',
        marginTop:'25px'
    };

    var stylesContentDropdown = {
        margin: '10px',
        float: 'left',
        marginTop:'20px'
    };

    function printPage() {
        window.print();
    }

    if (props.user && !currentUser) {
        if (props.user.role_id == 3 || props.user.role_id == 4) {
            setCurrentUser(props.user.id);
        }
    }

    let userDataComponent = null;

    if (users.length!=0)
        userDataComponent = (
            <>
                <div style={stylesContentDropdown}><span className="custom-dropdown">
                <select style={{"width" : "200px"}} name="userData" onChange={(e) => setCurrentUser(e.target.value)}>
                    <option>Select a User</option>

                    {users.map((user) => {
                         return <>
                            <option key={user.id} id={user.name} value={user.id}>{user.name}</option>
                        </>
                    })}
                    <option key="0" id="AllUsers" value="0">All Users</option>
                    </select>
                   </span>
                </div>
            </>
        );

    let dateRangeComponent = null;

    if (startDate && endDate)
        dateRangeComponent = (
            <>
                <div style={stylesContent}>
                    <DatetimePickerTrigger
                    moment={initialMoment}
                    onChange={(e) => setStartDate(e)}
                    showTimePicker={false}
                    maxDate={initialMoment}>
                    Start: <input type="text" value={startDate.format('MM/DD/YYYY')} readOnly size="10" style={{ textAlign: 'center'}} />
                </DatetimePickerTrigger>
                </div>
                <div style={stylesContent}>
                   <DatetimePickerTrigger
                        moment={endDate}
                        onChange={(e) => setEndDate(e)}
                        showTimePicker={false}
                        maxDate={initialMoment}>
                       End: <input type="text" value={endDate.format('MM/DD/YYYY')} readOnly size="10" style={{ textAlign: 'center'}} />
                    </DatetimePickerTrigger>
                </div>
            </>
        );




    const columns = React.useMemo(
        () => [
            {
                Header: 'Date',
                id: 1,
                minWidth: 150,
                filterable: false,
                accessor: d => {
                    if (d.created_at) {
                        return moment.utc(d.created_at)
                            .local()
                            .format("MM/DD/YYYY hh:mm A")
                    } else {
                        return "None Recorded"
                    }
                }
             }
            ,
            {
                Header: 'User',
                id: 2,
                accessor: 'user.name',
            },
            {
                Header: 'Account',
                accessor: 'ticket.procedure_task.org_account_id',
            },
            {
                Header: 'Queue',
                accessor: 'ticket_queue.name',
            },
            {
                Header: 'Action',
                id: 4,
                minWidth: 150,
                accessor: d => {
                    if (d.ticket_action) {
                        return <div style={{ textAlign: "center" }}>{(d.ticket_action.name) ? d.ticket_action.name : "-"}</div>;
                    } else {
                        return <div style={{ textAlign: "center" }}>-</div>;
                    }

                }
            },
            {
                Header: 'Note',
                accessor: 'note',
                minWidth: 300,
                filter: 'includes',
            },
        ],
        [  ]
    )

    if (currentUser==0)  {
        columns[1]["show"] = true;
    } else {
        columns[1]["show"] = false;
    }



    function Table({ columns, data }) {
        // Use the state and functions returned from useTable to build your UI
        const {
            getTableProps,
            getTableBodyProps,
            headerGroups,
            rows,
            prepareRow,
        } = useTable({
            columns,
            data,
        })
        // Render the UI for your table
        return (
            <table style={{width: '100%'}} {...getTableProps()}>
                <thead>
                {headerGroups.map(headerGroup => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map(column => (
                            <th {...column.getHeaderProps()}>{column.render('Header')}</th>
                        ))}
                    </tr>
                ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                {rows.map(
                    (row, i) => {
                        prepareRow(row);
                        return (
                            <tr {...row.getRowProps()}>
                                {row.cells.map(cell => {
                                    return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                                })}
                            </tr>
                        )}
                )}
                </tbody>
            </table>
        )
    }

    function handleSearchChange(event) {
        setSearchInput(event.target.value);
    };

    useEffect(() => {
        globalSearch();
    }, [searchInput]);

    function globalSearch () {

        let filteredData = userLogData.filter(value => {
            if (value.ticket_action && value.ticket_action.name) {
                return (
                    value.ticket.procedure_task.org_account_id.toString().toLowerCase().includes(searchInput.toLowerCase()) ||
                    value.ticket_queue.name.toString().toLowerCase().includes(searchInput.toLowerCase()) ||
                    value.note.toString().toLowerCase().includes(searchInput.toLowerCase()) ||
                    value.ticket_action.name.toString().toLowerCase().includes(searchInput.toLowerCase()) ||
                    value.user.name.toString().toLowerCase().includes(searchInput.toLowerCase()) ||
                    moment.utc(value.created_at).local().format("MM/DD/YYYY hh:mm A").toString().toLowerCase().includes(searchInput.toLowerCase())

                );
            } else {
                return (
                    value.ticket.procedure_task.org_account_id.toString().toLowerCase().includes(searchInput.toLowerCase()) ||
                    value.ticket_queue.name.toString().toLowerCase().includes(searchInput.toLowerCase()) ||
                    value.note.toString().toLowerCase().includes(searchInput.toLowerCase()) ||
                    value.user.name.toString().toLowerCase().includes(searchInput.toLowerCase()) ||
                    moment.utc(value.created_at).local().format("MM/DD/YYYY hh:mm A").toString().toLowerCase().includes(searchInput.toLowerCase())

                );
            }

        });
        setTableData(filteredData);
    };


   /* setStartDate(moment().subtract(7,'d').format('YYYY-MM-DD'));
    setEndDate(moment().format('YYYY-MM-DD'));*/

    if (!userLogData && !users) {
        return ( "loading...")
    } else {
        return (
            <>
                <div className="flex-large" >
                        <Modal.Header closeButton>
                            <Modal.Title>User Ticket Log</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            {userDataComponent}
                            {dateRangeComponent}
                            <div style={{float: 'left', marginTop:'20px',  marginLeft:'10px', marginRight:'15px'}}><button className="btn btn-success" onClick={loadUserLogData} >Load User Log Data</button></div>
                            <button className="btn btn-success" style={{float:'left', marginLeft:'10px',marginTop:'20px'}}  onClick={() => printPage()}>Print</button>
                            <div style={{clear: 'left', float: 'left', marginLeft:'20px', marginTop:'15px'}}>Search for Data in any Column: <input
                                size="large"
                                name="searchInput"
                                value={searchInput || ""}
                                onChange={handleSearchChange}
                                label="Search"
                            />
                            </div>


                            <div style={{float: 'none', clear: 'left', textAlign: 'center', paddingTop :'15px'}}>
                               <ReactTable
                                   columns={columns}
                                   data={tableData}
                                   filterable={false}
                                   minRows={10}
                                   defaultPageSize={10} />
                            </div>
                        </Modal.Body>
                </div>
            </>
        )
    }
}

export default ViewUserLog