import React, { useState } from 'react';
import axios from 'axios';
import styled from 'styled-components'
import {
    Container, Row, Col, Button, Card, Modal
} from 'react-bootstrap';
import { useTable } from 'react-table'
import * as Icon from 'react-bootstrap-icons';
import AddUserForm from './AddUserForm'
import EditUserForm from './EditUserForm'
import './Users.css';
import SweetAlert from 'react-bootstrap-sweetalert';
import { Redirect } from "react-router-dom";
import ReactTable from 'react-table-6'
import 'react-table-6/react-table.css'

const Styles = styled.div`
  padding: 1rem;

  table {
    border-spacing: 0;
    border: 1px solid black;

    tr {
      :last-child {
        td {
          border-bottom: 0;
        }
      }
    }

    th,
    td {
      margin: 0;
      padding: 0.5rem;
      border-bottom: 1px solid black;
      border-right: 1px solid black;

      :last-child {
        border-right: 0;
      }
    }
  }
`

const Users = (props) => {

    const [data, setData] = useState(null);
    const [alert, setAlert] = useState(null);
    const [editing, setEditing] = useState(false)
    const initialFormState = { id: null, name: '', email: '', role: '', }
    const [users, setUsers] = useState(null);
    const [currentUser, setCurrentUser] = useState(initialFormState);
    const [show, setShow] = useState(false);
    const [toWorkQueue, setToWorkQueue] = useState(false);
    const [editShow, setEditShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleEditClose = () => setEditShow(false);
    const handleShow = () => setShow(true);
    const handleEditShow = () => setEditShow(true);

    if (props.user) {
        if (props.user.role_id == 4 || props.user.role_id == 3) {
            window.location.href = "/work-queue/";
        }
    }

    if ((!data) && (props.user)) {
        props.toggleLoading(true);
        axios.get('api/admin/user')
            .then(res => {
                props.toggleLoading(false);
                setData(res.data);
            })
    }

    function handleEdit({ row }) {
        setEditing(true)
        setCurrentUser({
            id: row.id,
            name: row.name,
            email: row.email,
            role_id: row.role_id,
        })
        let data = {
            name: row.name,
            email: row.email,
            role_id: row.role_id,
        }

        handleEditShow();
    }


    const addUser = user => {
        let data = {
            name: user.name,
            email: user.email,
            role_id: user.role,
        }

        axios.post('api/admin/user', data)
            .then(res => {
                axios.get('api/admin/user')
                    .then(res => {
                        setShow(false);
                        setData(null);
                        setData(res.data);
                    })
            });


    }
    /*
        const deleteUser = id => {
            setEditing(false)
            setUsers(users.filter(user => user.id !== id))
        }




        }*/

    const updateUser = (id, updatedUser) => {
        setEditing(false)
        let data = {
            name: updatedUser.name,
            email: updatedUser.email,
            role_id: updatedUser.role_id,
        }

        axios.put('api/admin/user/'+id, data)
            .then(res => {
                axios.get('api/admin/user')
                    .then(res => {
                       // setShow(false);
                        setData(null);
                        setData(res.data);
                    })
            });

        //setUsers(users.map(user => (user.id === id ? updatedUser : user)))
    }

    const editRow = user => {
        setEditing(true)
        setCurrentUser({
            id: user.id,
            name: user.name,
            email: user.email,
        })
    }

    function deleteConfirmation(row)  {

        const getAlert = (row) => ( <SweetAlert
            warning
            showCancel
            confirmBtnText="Yes, delete the user!"
            confirmBtnBsStyle="danger"
            title="Are you sure?"
            onCancel={()=>hideAlert()}
            onConfirm={()=>handleDelete(row.id)}
            >
            Are you sure you want to delete {row.name}?
        </SweetAlert>
    );

        setAlert(getAlert(row.original));

    }

    function hideAlert() {
        setAlert(null)
    }


    function handleDelete(id) {

        axios.delete('api/admin/user/' + id)
            .then(res => {
                axios.get('api/admin/user')
                    .then(res => {
                        setShow(false);
                        setData(null);
                        setData(res.data);
                        setAlert(null);
                    })
            })
            .catch((err) => {
                console.log(err);
            })

    }

    function resetPassword ({row}) {
        let data = {
          email: row.email,
        }
        axios.post('password/email', data)
            .then(res => {
                axios.get('api/admin/user')
                    .then(res => {
                        setShow(false);
                        setData(null);
                        setData(res.data);
                    })
            });
    }

    function impersonateUser ({row}) {
        axios.get('sysadmin/beuser/'+row.id)
            .then(res => {
                window.location.href = "/work-queue/";
            });
    }

    const columns = React.useMemo(
        () => [
            { Header: 'id', accessor: 'id', show: false },
            {
                Header: '',
                id: 1,
                resizable: false,
                Cell: row => (
                    <div>
                    <Icon.Pencil color="royalblue" size={32} onClick={() => handleEdit(row)}  />
    <Icon.XCircle color="red" size={32} onClick={() => deleteConfirmation(row)} />
    </div>
)
},  { Header: 'Name', accessor: 'name', },
    { Header: 'Email Address', accessor: 'email', },
    { Header: 'Role', accessor: 'role.name', },

    {
        Header: ' Reset Password',
            id: 4,
        Cell: row => (
        <div>
        <Icon.BootstrapReboot color="royalblue" size={32} onClick={() => resetPassword(row)} />
    </div>
    )
    },

            {
        Header: 'Impersonate',
            id: 4,
                show: false,
            Cell: row => (
            <div>
            <Icon.Person color="royalblue" size={32} onClick={() => impersonateUser(row)} />
        </div>
    )
    },



    ],
    []
)

    if(props.user) {
        if (props.user.is_admin == 1 ) {
            columns[6]["show"] = true;
        }
    }


    function Table({ columns, data }) {
        // Use the state and functions returned from useTable to build your UI
        const {
            getTableProps,
            getTableBodyProps,
            headerGroups,
            rows,
            prepareRow,
        } = useTable({
            columns,
            data,
        })
        // Render the UI for your table
        return (
            <table style={{width: '100%'}} {...getTableProps()}>
    <thead>
        {headerGroups.map(headerGroup => (
                <tr {...headerGroup.getHeaderGroupProps()}>
        {headerGroup.headers.map(column => (
            <th {...column.getHeaderProps()}>{column.render('Header')}</th>
        ))}
    </tr>
    ))}
    </thead>
        <tbody {...getTableBodyProps()}>
        {rows.map(
            (row, i) => {
                prepareRow(row);
                return (
                    <tr {...row.getRowProps()}>
                {row.cells.map(cell => {
                    return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                })}
            </tr>
            )}
        )}
    </tbody>
        </table>
    )
    }

    if (!data || !props.user) {
        return (
            <div></div>
    );
    } else {
        return (
            <>
                {props.user.role_id == 4 &&
                    <Redirect to="/"/>
                }
            <Container>
            <Row>
            <Col>
            <Card className="users-card content-card">
            <Card.Header>Users</Card.Header>
            <Card.Body>
            <Row><Col className="text-right">
            <Button className={['btn-success']} variant="primary" onClick={handleShow} style={{marginRight: '15px', marginBottom: '10px'}}>
            Add a User
        </Button>
            </Col></Row>
            <Row><Col sm="12" className="text-center" >
           {/* <Styles>
            <Table columns={columns} data={data} />
        </Styles>*/}
            <ReactTable
                data={data}
                columns={columns}
                defaultPageSize={10}
            />

           </Col></Row>

        <Modal show={show} onHide={handleClose}>
        <AddUserForm addUser={addUser} />
        </Modal>

        <Modal show={editShow} onHide={handleEditClose}>
            <EditUserForm handleEditClose={handleEditClose} updateUser={updateUser} currentUser={currentUser}  handleEdit={handleEdit} />
        </Modal>


        </Card.Body>
        </Card>
        </Col>
        </Row>
        {alert}
        </Container>
        </>

    );
    }
}

export default Users;