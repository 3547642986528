import React, { useState,  useEffect, useRef, Suspense, useCallback, Component, createContext  } from 'react';
import PropTypes from "prop-types";
import ReactDOM from 'react-dom'
import { Redirect } from "react-router-dom";
import axios from 'axios';
import styled from 'styled-components'
import {
    Container, Row, Col, Form, FormControl, InputGroup, Button, Nav, Navbar, NavDropdown, Card, Modal
} from 'react-bootstrap';
import * as Icon from 'react-bootstrap-icons';
import moment from 'moment'
import ViewLog from './ViewLog'
import RecordsTable from './RecordsTable'
/*import FoldableTableHOC from "../hoc/foldableTable";*/
import ReactTable  from 'react-table-6'
import 'react-table-6/react-table.css'
import classnames from "classnames";
import selectTableHOC from "react-table-6/lib/hoc/selectTable";



const Ticket = (props) => {

    const TicketStateContext = React.createContext()


    const initialQueueValue = [{ id: 0, value: " Select A Queue " }];
    const [data, setData] = useState([]);
    const [originalData, setOriginalData] = useState([]);
    const [uniqueTicketQueueIDs, setUniqueTicketQueueIDs] = useState(null);
    const [queues, setQueues] = useState(null);
    const [selectedQueue, setSelectedQueue] = useState(null);
    const [dataLoading, setDataLoading] = React.useState(false)
    const [dataLoaded, setDataLoaded] = React.useState(false)
    const [tickets, setTickets] = useState(null);
    const [selected, setSelected] = useState(null);
    //const [selection, setSelection] = useState(null);
    const [show, setShow] = useState(false);
    const [ticketID, setTicketID] = useState(0);
    const [searchInput, setSearchInput] = useState(0);
    const [selectedRows, setSelectedRows] = useState([]);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    var currentlySelectedRows = [];

    const SelectTable = selectTableHOC(ReactTable);

    /*const FoldableTable = FoldableTableHOC(ReactTable);*/

    if(props.user && !queues) {
        axios('api/ticketing/queue?per_page=100').then(res => {
            let mappedQueues = [];
            res.data.data.forEach((queue) => {
                mappedQueues[queue.id] = queue;
            })
            // console.log(mappedQueues);
            setQueues(mappedQueues)
        });
    }

    if (!data.length && props.user && !dataLoaded) {
        //props.toggleLoading(false);
        // console.log('get records!');
        //setDataLoading(false);
        axios.get('api/ticketing/ticket?with=procedure_task,status&per_page=20000')
            .then(res => {
                props.toggleLoading(false);
                setData(res.data.data);
                setOriginalData(res.data.data);
                setDataLoading(false);
                setDataLoaded(true);
            })
    }

    let queueComponent = null;

    if (props.user && queues) {
        queueComponent = (
            /* <span className="custom-dropdown"><select name="type" id="type" onChange={(e) => setSelectedQueue(e.target.value)}>*/
            <span className="custom-dropdown"><select name="type" id="select_queues">
            <option key="0" value="0"> Select A Queue </option>
        {queues.map((queue, index) => {
            return <option key = {index} value = {queue.id} > {queue.name} < /option>
        })}
    </select></span>
    );
    }



    const showViewLog =  (row) => {
        setTicketID(row.original.id);
        handleShow()
    }


    const handleAllRowsClick = (e, allRowsProps) => {
        console.log(e,allRowsProps);
    }
    const handleCheckboxClick = (e, row) => {
        // console.log(e,row);
        // console.log(data);
        toggleRecordCheck(row.id);
    }

    const toggleRecordCheck = (rowId) => {
        /*let tempRecords = data.map((record) => {
            if (record.id == rowId) {
                let checked = false;
                if (record.checked != true) {
                    checked = true;
                }
                record.checked = checked;
                console.log(record);
            }
            return record;
        });
        setData(tempRecords);
*/

        let tempSelectedRows = selectedRows;
        if (tempSelectedRows.indexOf(rowId) === -1) {
            tempSelectedRows.push(rowId);
        } else {
            tempSelectedRows = tempSelectedRows.filter((row) => row != rowId);
        }
        setSelectedRows(tempSelectedRows);
        console.log(tempSelectedRows);
    }


    const columns = React.useMemo(() => [
        {
            Header: '',
            id: 10,
            filterable: false,
            maxWidth: 35,
            Cell: row => (
                <div>
                <Icon.TextLeft color="black" size={32} onClick={() => showViewLog(row)} />
    </div>
)
},
    { Header: 'Account ID', id: 'account_id',  filterable: false, accessor: 'procedure_task.org_account_id',
        maxWidth: 100,
    },

    { Header: 'Queue',
        id: "queue",
        filterable: false,
        accessor: d => {
        if (d.ticket_queue_id) {
            return queues[d.ticket_queue_id].name;
        } else {
            return "Unassigned";
        }
    },
        filter: 'includes', },
    { Header: 'PT Code', id: "patient_code", filterable: false, accessor: 'procedure_task.patient_code',  maxWidth: 75,
        filter: 'includes', },
    { Header: 'Procedure Description',  filterable: false, accessor: 'procedure_task.procedure_description',
        filter: 'includes', },
    { Header: 'Tooth',foldable: true, filterable: false, accessor: 'procedure_task.tooth', show: false, },
    { Header: 'id',foldable: true, filterable: false, accessor: 'id', show: false, },
    { Header: 'Amount', id: 'procedure_task_amount',  filterable: false, accessor: d=>Number(d.procedure_task.amount),
        Cell: props => {
        return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(props.value);
    }
    },
    { Header: 'Doctor',filterable: false, accessor: 'procedure_task.doctor_full_name',

        filter: 'includes', },
],
    [queues, data]
)

    var stylesContent = {
        margin: '10px',
        float: 'left',
    };

    const subComponent = row => {
        console.log(row.original);
        return (
            <div style={{textAlign: 'left',paddingLeft: '50px'}} key={ row.id }>
            <div style={stylesContent}><strong>Tooth: </strong>{ row.original.procedure_task.tooth }</div>
        <div style={stylesContent}><strong>Diagnosis Date: </strong>{  moment(row.original.procedure_task.diagnosis_date).local().format("MM/DD/YYYY")  } </div>
        <div style={stylesContent}><strong>Procedure Code: </strong>{ row.original.procedure_task.procedure_code }</div>
        <div style={stylesContent}><strong>Next Action At: </strong>{  moment(row.original.next_action_at).local().format("MM/DD/YYYY hh:mm:ss a") } </div>
        <div style={stylesContent}><strong>Last Action at: </strong>{  moment(row.original.last_action_at).local().format("MM/DD/YYYY hh:mm:ss a") } </div>

        </div>
    );
    };

    function handleSearchChange(event) {
        setSearchInput(event.target.value);
    };

    useEffect(() => {
        globalSearch();
    }, [searchInput]);

    function globalSearch () {
        let filteredData = originalData.filter(value => {
            return (
                value.procedure_task.doctor_full_name.toLowerCase().includes(searchInput.toLowerCase()) ||
                value.procedure_task.amount.toLowerCase().includes(searchInput.toLowerCase()) ||
                value.procedure_task.amount.toLowerCase().includes(searchInput.toLowerCase()) ||
                value.procedure_task.procedure_description.toLowerCase().includes(searchInput.toLowerCase()) ||
                value.procedure_task.org_account_id.toString().toLowerCase().includes(searchInput.toLowerCase())
            );
        });
        setData(filteredData);
    };

    function setParentSelection(selection)  {
        console.log("selection");
        console.log(selection);
        currentlySelectedRows = selection;
        /* <TicketStateContext.Provider value={ selection } >*/
        //TicketStateContext.setSelection(selection);
        //currentSelectionStore.dispatch()
        /*return (
            <Provider store={store}>
            <Client communityIds={communityIds}/>
        </Provider>
    );*/
    }

    function assignTicketsToQueue() {
        //const currentSelection = React.useContext(TicketStateContext);
        //TicketStateContext
        console.log("currentlySelectedRows");
        console.log(currentlySelectedRows);

        let e = document.getElementById("select_queues");
        let selectedQueueId = e.options[e.selectedIndex].value;
        selectedRows.forEach((record) => {
            axios.get('api/ticketing/ticket/queue/assign/'+ record + '/' +selectedQueueId)
                .then(res => {
                    setData(data.map((updateRecord) => {
                        if (record == updateRecord.id) {
                            updateRecord.ticket_queue_id = selectedQueue;
                        }
                        return updateRecord;
                    }))
                })
        })

    }

    class MyTable extends Component {

        constructor(props) {
            super(props);
        }

        static defaultProps = {
            keyField: "id"
        };

        static propTypes = {
            keyField: PropTypes.string
        };


        sendData = (selection) => {
            this.props.setParentSelection(selection);
        }

        /**
         * Toggle a single checkbox for select table
         */
        toggleSelection = (key, shift, row) => {
            // start off with the existing state
            console.log("props");
            console.log(props);
            let selection = [...this.state.selection];
            //let selectedRows = [...this.state.selectedRows];
            const keyIndex = selection.indexOf(key);

            // check to see if the key exists
            if (keyIndex >= 0) {
                // it does exist so we will remove it using destructing
                selection = [
                    ...selection.slice(0, keyIndex),
                    ...selection.slice(keyIndex + 1)
                ];
            } else {
                // it does not exist so add it
                selection.push(key);
                //selectedRows.push(key);
            }
            // update the state
            this.setState({ selection });
            this.sendData(selection);
            //setSelectedRows(selectedRows);
            console.log("selection");
            console.log(selection);
            //console.log("selectedRows");
            // console.log(selectedRows);
        };


        /**
         * Toggle all checkboxes for select table
         */
        toggleAll = () => {
            const { keyField } = this.props;
            const selectAll = !this.state.selectAll;
            const selection = [];

            if (selectAll) {
                const wrappedInstance = this.checkboxTable.getWrappedInstance();
                const currentRecords = wrappedInstance.getResolvedState().sortedData;
                currentRecords.forEach(item => {
                    selection.push(`select-${item._original[keyField]}`);
                });
            }
            this.setState({ selectAll, selection });
            this.sendData(selection);
        };

        /**
         * Whether or not a row is selected for select table
         */
        isSelected = key => {
            return this.state.selection.includes(`select-${key}`);
        };

        rowFn = (state, rowInfo, column, instance) => {
            const { selection } = this.state;

            return {
                onClick: (e, handleOriginal) => {
                    console.log("It was in this row:", rowInfo);
                    if (handleOriginal) {
                        handleOriginal();
                    }
                },
                style: {
                    background:
                        rowInfo &&
                        selection.includes(`select-${rowInfo.original.id}`) &&
                        "lightgreen"
                }
            };
        };

        state = {
            selectAll: false,
            selection: []
        };

        render() {
            return (
                <SelectTable
            {...this.props}
            ref={r => (this.checkboxTable = r)}
            ref={r => (this.wrappedInstance = r)}
            toggleSelection={this.toggleSelection}
            selectAll={this.state.selectAll}
            selectType="checkbox"
            toggleAll={this.toggleAll}
            isSelected={this.isSelected}
            getTrProps={this.rowFn}
            setParentSelection = {this.setParentSelection}
            />
        );
        }
    }

    /*const defaultExpandedRows = data.map((element, index) => {return {index: true}});*/

    return (

        <Container fluid={true}>
        <Row>
        <Col>
        <Card className="content-card">
        <Card.Header>Records</Card.Header>
        <Card.Body>
        <Row>
        <div style={{float:'left', marginLeft:'30px'}}>
    {queueComponent}
</div>
    <div style={{float:'left', marginLeft:'30px', marginBottom: '10px'}}>
<Button className={['btn-success']} variant="primary" onClick={assignTicketsToQueue}  style={{marginRight: '15px'}}>
    Add Selected Records to Queue
    </Button>

    </div>
    <div style={{float:'left', marginLeft:'30px', marginBottom: '10px'}}>Search: <input
    size="large"
    name="searchInput"
    value={searchInput || ""}
    onChange={handleSearchChange}
    label="Search"
        />
        </div>

        </Row>
    {/*  <RecordsTable
            handleAllRowsClick={handleAllRowsClick}
            handleCheckboxClick={handleCheckboxClick}
            showViewLog={showViewLog}
            data={data}
            queues={queues}
        />*/}
    {/*  <ReactTable
            data={data}
            columns={columns}
            defaultPageSize={10}
            filterable={true}
            /!* pivotBy={["selection"]}*!/
            SubComponent={ subComponent }
            />*/}

<MyTable
    data={data}
    columns={columns}
    defaultPageSize={10}
    filterable={true}
    /* pivotBy={["selection"]}*/
    SubComponent={ subComponent }
    setParentSelection = {setParentSelection}
    keyField="id"
        />

        <div style={{float:'left', marginLeft:'20px',  marginLeft:'10px', fontStyle:'italic'}}>* You can sort any column by clicking on the header text.  Hold Shift while selecting columns to sort by more than on column at a time.</div>
    <Modal show={show} onHide={handleClose} dialogClassName="view-log-modal">
        <ViewLog  ticketID={ticketID}  {...props} />
    </Modal>

    </Card.Body>
    </Card>
    </Col>
    </Row>
    </Container>

);
}




export default Ticket;
