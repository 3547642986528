import React, {useState} from 'react';
import logo from './logo.svg';
import Account from './Components/Account/Account';
import Header from './Components/Header/Header';
import Login from './Components/Login/Login';
import Logout from './Components/Logout/Logout';
import Organizations from './Components/Organizations/Organizations';
import Organization from './Components/Organization/Organization';
import Users from './Components/Users/Users';
import Queues from './Components/Queues/Queues';
import Records from './Components/Records/Records';
import Reports from './Components/Reports/Reports';
import RecordsTest from './Components/Records/RecordsTest';
import WorkQueue from './Components/WorkQueue/WorkQueue';
import Upload from './Components/Upload/Upload';
import Home from './Components/Home/Home';
import PasswordReset from './Components/PasswordReset/PasswordReset';
import Loading from './Components/Common/Loading';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  Redirect
} from "react-router-dom";
import axios from 'axios';

const App = () => {
  const [user, setUser] = useState(null);
  const [redirect, setRedirect] = useState(null);
  const [loading, setLoading] = useState(null);

  const authTest = () => {
    if (user) {
      return;
    }
    // Is user currently authorized?
    axios.get('auth/token/update')
    .then(res => {
        window.token = res.data.token;
        // User is authenticated, get and set user
        toggleLoading(false);
        getUser();
    })
    .catch (error => {
      // User is not authenticated
      toggleLoading(false);
      setRedirect('/login');
    })
  }

  const getUser = () => {
    axios.get('api/user')
    .then(res => {
        setUser(res.data);
        //setRedirect('/');
    })
  }

  const toggleLoading = (loading) => {
    setLoading(loading);
  }

  authTest();

  return (
    <Router>
      <div className="App">
        <Loading loading={loading} />
        <Header user={user} />
        <Route exact path="/" render={(props) => <WorkQueue {...props} user={user} toggleLoading={toggleLoading} />}/>
        <Route exact path="/account" render={(props) => <Account {...props} setUser={setUser} user={user} toggleLoading={toggleLoading} getUser={getUser} authTest={authTest} />}/>
        <Route exact path="/login" render={(props) => <Login {...props} user={user} toggleLoading={toggleLoading} getUser={getUser} authTest={authTest} />}/>
        <Route exact path="/logout" render={(props) => <Logout {...props} user={user} toggleLoading={toggleLoading} setUser={setUser} />}/>
        <Route exact path="/upload" render={(props) => <Upload {...props} user={user} toggleLoading={toggleLoading} setUser={setUser} />}/>
        <Route exact path="/organizations" render={(props) => <Organizations user={user} toggleLoading={toggleLoading} {...props} />}/>
        <Route exact path="/organization" render={(props) => <Organization user={user} toggleLoading={toggleLoading} {...props} />}/>
        <Route exact path="/queues" render={(props) => <Queues  user={user} toggleLoading={toggleLoading} {...props} />}/>
        <Route exact path="/records" render={(props) => <Records user={user} toggleLoading={toggleLoading} {...props} />}/>
        <Route exact path="/reports" render={(props) => <Reports user={user} toggleLoading={toggleLoading} {...props} />}/>
        <Route exact path="/records-test" render={(props) => <RecordsTest user={user} toggleLoading={toggleLoading} {...props} />}/>
        <Route exact path="/users" render={(props) => <Users user={user} toggleLoading={toggleLoading} {...props} />}/>
        <Route exact path="/reset-password" render={(props) => <PasswordReset user={user} toggleLoading={toggleLoading} {...props} />}/>
        <Route exact path="/work-queue" render={(props) => <WorkQueue user={user} toggleLoading={toggleLoading} {...props} />}/>


        
        { redirect && <Redirect to={redirect} /> }
      </div>
    </Router>
  );
}

export default App;
