import React, { useState, useEffect } from 'react'
import ReactDOM from 'react-dom'
import axios from 'axios';
import {
    Container, Row, Col, Form, FormControl, InputGroup, Button, Nav, Navbar, NavDropdown, Card, Modal
} from 'react-bootstrap';

const AddQueueForm = (props) => {

    const initialFormState = { id: null, name: '', type: ''}
    const [queue, setQueue] = useState(initialFormState);
    const [queueTypeData, setQueueTypeData] = useState(null);
    const initialQueueValue = [{ id: 0, value: " Select A Queue Type " }];
    const [queues, setQueues] = useState(initialQueueValue);

    const handleInputChange = event => {
        const { name, value } = event.target
        setQueue({ ...queue, [name]: value })
    }

    function onQueueTypeSelection(e) {
        setQueueTypeData(e.target.value);
    }

    let queueTypeComponent = null;

    // useEffect to get Queues
    useEffect(() => {
        axios('api/ticketing/queue/types').then(res => {
            setQueues(res.data)
            }
        );
    }, []);

    queueTypeComponent = (
        /*<select onChange={onQueueTypeSelection} >*/
        <select name="type" id="type" onChange={handleInputChange}>
        <option key="0" value="0"> Select A Queue Type </option>
        {queues.map((queue, index) => {
            return <option key = {index} value = {queue.id} > {queue.name} < /option>
            })}
        </select>
    );

    if (1==2) {
        return (
            <div>Loading... { queues.length } </div>
    );
    } else {
    return (
        <>
        <form
            onSubmit={event => {
                event.preventDefault()
                if (!queue.name) return
                props.addQueue(queue)
                setQueue(initialFormState)
            }}
        >
    <Modal.Header closeButton>
        <Modal.Title>Add a Queue</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <div className="flex-large">

        <Row>
            <Form.Label column sm="4" className="text-right">Queue Name</Form.Label>
            <Col sm="8" className="text-left"><input type="text" name="name" value={queue.name} onChange={handleInputChange} /></Col>
            </Row>
            <Row>
            <Form.Label column sm="4" className="text-right">Queue Type</Form.Label>
            <Col sm="8" className="text-left">
                    {queueTypeComponent}
            </Col>
            </Row>
        </div>
        </Modal.Body>
            <Modal.Footer>
            <button className="btn btn-success" >Add new queue</button>
        </Modal.Footer>
            </form>
        </>
        )
    }
}

export default AddQueueForm