import React, { useState, useEffect } from 'react'
import ReactDOM from 'react-dom'
import axios from 'axios';
import {
    Container, Row, Col, Form, FormControl, InputGroup, Button, Nav, Navbar, NavDropdown, Card, Modal
} from 'react-bootstrap';


const EditQueueForm = props => {
    const [ queue, setQueue ] = useState(props.currentQueue)
    const [queueTypeData, setQueueTypeData] = useState(null);
    const initialQueueValue = [{ id: 0, value: " Select A Queue Type " }];
    const [queues, setQueues] = useState(initialQueueValue);

    useEffect(
        () => {
            setQueue(props.currentQueue)
        },
        [ props ]
    )
    // You can tell React to skip applying an effect if certain values haven’t changed between re-renders. [ props ]

    const handleInputChange = event => {
        const { name, value } = event.target
        setQueue({ ...queue, [name]: value })
    }

    let queueTypeComponent = null;

    // useEffect to get Queues
    useEffect(() => {
        axios('api/ticketing/queue/types').then(res => {
                setQueues(res.data)
            }
        );
    }, []);

    queueTypeComponent = (
        /*<select onChange={onQueueTypeSelection} >*/
        <select name="type" id="type" onChange={handleInputChange}>
            <option key="0" value="0"> Select A Queue Type </option>
            {queues.map((queue, index) => {
                console.log(queue);
                return <option key = {index} value = {queue.id} > {queue.name} < /option>
                    })}
                </select>
                    );

    return (
        <>
            <form
                onSubmit={event => {
                    event.preventDefault()
                    props.updateQueue(queue.id, queue)
                }}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Edit Queue</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="flex-large">

                        <Row>
                            <Form.Label column sm="4" className="text-right">Queue Name</Form.Label>
                            <Col sm="8" className="text-left"><input type="text" name="name" value={queue.name} onChange={handleInputChange} /></Col>
                        </Row>
                        <Row>
                            <Form.Label column sm="4" className="text-right">Queue Type</Form.Label>
                            <Col sm="8" className="text-left">
                                {queueTypeComponent}
                            </Col>
                        </Row>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button className="btn btn-success" >Update queue</button>
                </Modal.Footer>
            </form>
        </>


)
}

export default EditQueueForm