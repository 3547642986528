import React, { useState, useEffect } from 'react'
import {
    Container, Row, Col, Form, FormControl, InputGroup, Button, Nav, Navbar, NavDropdown, Card, Modal
} from 'react-bootstrap';
import axios from 'axios';
import MaskedInput from 'react-maskedinput'

const EditOrganizationForm = props => {
    const [ organization, setOrganization ] = useState(props.currentOrganization)

    useEffect(
        () => {
            setOrganization(props.currentOrganization)
        },
        [ props ]
    )
    // You can tell React to skip applying an effect if certain values haven’t changed between re-renders. [ props ]

    const handleInputChange = event => {
        const { name, value } = event.target
        setOrganization({ ...organization, [name]: value })
    }

    return (
        <form
    onSubmit={event => {
        event.preventDefault()
        props.updateOrganization(organization.id, organization)
    }}
>
<Modal.Header closeButton>
    <Modal.Title>Edit Organization</Modal.Title>
    </Modal.Header>
    <Modal.Body>
    <Row>
    <Form.Label column sm="4" className="text-right">Name</Form.Label>
        <Col sm="8" className="text-left">
    <input type="text" name="name" value={organization.name} onChange={handleInputChange} /></Col>
    </Row>
    <Row>
    <Form.Label column sm="4" className="text-right">Address 1</Form.Label>
        <Col sm="8" className="text-left">
    <input type="text" name="address1" value={organization.address1} onChange={handleInputChange} /></Col>
    </Row>
    <Row>
    <Form.Label column sm="4" className="text-right">Address 2</Form.Label>
        <Col sm="8" className="text-left">
    <input type="text" name="address2" value={organization.address2} onChange={handleInputChange} /></Col>
    </Row>
    <Row>
    <Form.Label column sm="4" className="text-right">City</Form.Label>
        <Col sm="8" className="text-left">
    <input type="text" name="city" value={organization.city} onChange={handleInputChange} /></Col>
    </Row>
    <Row>
    <Form.Label column sm="4" className="text-right">State</Form.Label>
        <Col sm="8" className="text-left">
    <input type="text" name="state" value={organization.state} onChange={handleInputChange} /></Col>
    </Row>
    <Row>
    <Form.Label column sm="4" className="text-right">Zip</Form.Label>
        <Col sm="8" className="text-left">
        <input type="text" name="zip" value={organization.zip} onChange={handleInputChange} /></Col>
    </Row>
    <Row>
    <Form.Label column sm="4" className="text-right">Phone</Form.Label>
        <Col sm="8" className="text-left">
        <MaskedInput mask="(111) 111-1111" name="phone" size="20" value={organization.phone} onChange={handleInputChange} />
    </Col>
    </Row>
        </Modal.Body>
        <Modal.Footer>
        <button  className="btn btn-success">Update organization</button>
    <button className="btn btn-danger" onClick={() => props.handleEditClose()} >Cancel</button>
    </Modal.Footer>
        </form>
)
}

export default EditOrganizationForm