import React, { useState, Component } from 'react'
import moment from 'moment';
import ReactDOM from "react-dom";
import "imrc-datetime-picker/dist/imrc-datetime-picker.css";
import {DatetimePickerTrigger} from 'imrc-datetime-picker';

import {
    Container, Collapse, Row, Col, Form, FormControl, InputGroup, Button, Nav, Navbar, NavDropdown, Card, Modal
} from 'react-bootstrap';
import axios from 'axios';
import { useTable } from 'react-table'
import styled from 'styled-components'
import './AccountSearch.css';
import * as Icon from 'react-bootstrap-icons';
import Accordion from 'react-bootstrap/Accordion'
import { useAccordionToggle } from 'react-bootstrap/AccordionToggle';


const Styles = styled.div`
  padding: 1rem;

  table {
    border-spacing: 0;
    border: 1px solid black;

    tr {
      :last-child {
        td {
          border-bottom: 0;
        }
      }
    }

    th,
    td {
      margin: 0;
      padding: 0.5rem;
      border-bottom: 1px solid black;
      border-right: 1px solid black;

      :last-child {
        border-right: 0;
      }
    }
  }
`

const useForceUpdate = () => useState()[1];

const AccountSearch = props => {

    const [logData, setLogData] = useState(null);
    const [dataLoading, setDataLoading] = React.useState(false)
    const [dataLoaded, setDataLoaded] = React.useState(false)
    const [users, setUsers] = React.useState(false)
    const [message, setMessage] = React.useState(false);
    const [currentTicketID, setCurrentTicketID] = React.useState(false);
    const [selectedActions, setSelectedActions] = React.useState([]);
    const [accountNotes, setAccountNotes] = React.useState([]);
    const [currentMoment, setCurrentMoment] = React.useState( []);
    const [currentMomentSingle, setCurrentMomentSingle] = React.useState( moment() );
    const [cleared, setCleared] = React.useState( false );
    const [initialMoment, setInitialMoment] = React.useState(moment());
    const [showCustomActionsModal, setShowCustomActionsModal] = React.useState(false);
    const [showAccountNotesModal, setShowAccountNotesModal] = React.useState(false);
    const [open, setOpen] = useState(false);
    const handleCustomActionsModalClose = () => setShowCustomActionsModal(false);
    const handleCustomActionsModalShow = () => setShowCustomActionsModal(true);
    const handleAccountNotesModalClose = () => setShowAccountNotesModal(false);
    const handleAccountNotesModalShow = () => setShowAccountNotesModal(true);
    const [newNotes, setNewNotes] = React.useState( false );
    const [allRecords, setAllRecords] = React.useState( false );
    const [everyAccount, setEveryAccount] = React.useState( false );
    const [ticketData, setTicketData] = React.useState( false );
    const [accountID, setAccountID] = React.useState( "" );
    const [fullName, setFullName] = useState(0);
    const [phone, setPhone] = useState(0);
    const [accountIDInputValue, setAccountIDInputValue] = React.useState( "" );
    const [accountSearchMessage, setAccountSearchMessage] = React.useState( "" );
    const uniqueAccountIDs = [];


    const handleNoteChange = event => {
        const { name, value } = event.target
        setNewNotes(value);
    }

    const forceUpdate = useForceUpdate();
    const newMoment = moment();
    const shortcuts = {
        'Today': newMoment,
    };

    let currentMessage = "";
    let queueActionComponent = null;

    const updateRecords  = () => {
        let totalRecords=0;
        let processedRecords=0;
        let data = {}
        selectedActions.forEach((action) => {
            totalRecords++
            if (action.park_for_time_seconds) {
                let data = {
                    park_for_time_seconds: action.park_for_time_seconds
                }
            } else {
                let data = {}
            }

            axios.post('api/ticketing/ticket/action/'+ action.id + '/' +action.action, data)
                .then(res => {
                    processedRecords++
                    if (totalRecords==processedRecords) {
                        props.getQueueData(props.callingQueueID)
                        props.handleAccountSearchClose();
                    }
                });
        });
    }

    if (!everyAccount) {
        axios.get('api/ticketing/ticket?with=procedure_task&per_page=20000')
            .then(res => {
                setEveryAccount(res.data.data);
            })
    }


    const updateAccountSearch  = () => {
        if ((everyAccount.filter(item => item.procedure_task.org_account_id == accountIDInputValue).length > 0)) {
            const currentRecord = everyAccount.filter(item => item.procedure_task.org_account_id == accountIDInputValue);
            setAccountID(null);
            setAllRecords(null);
            setAccountNotes([]);
            setTicketData(null);
            setAccountID(accountIDInputValue);
            setPhone(currentRecord[0].procedure_task.phone);
            setFullName(currentRecord[0].procedure_task.full_name);

            setAccountSearchMessage("");


        } else {
            setAccountSearchMessage("Account ID Not Found.");
        }
    }


    const updateNotes  = () => {

        let data = {
            note: newNotes
        }

        axios.post('api/note/'+ accountID, data)
            .then(res => {
                setAccountNotes([]);
            });

    }


    const addToSelectedActions = (id, e) => {
        const filteredActions = selectedActions.filter(item => item.id !== id);
        setSelectedActions(filteredActions.concat({"id": id, "action": e.target.value}));

        if (e.target.value==4) {
            setCurrentTicketID(id);
            handleCustomActionsModalShow();
            document.getElementById("mainModal").style.opacity = "0.1";
        }
    }

    const openNotes = () => {
        handleAccountNotesModalShow();
        document.getElementById("mainModal").style.opacity = "0.1";

    }

    const closeNotes = () => {
        handleAccountNotesModalClose();
        document.getElementById("mainModal").style.opacity = "1";
    }

    const closeCustomActions = () => {
        handleCustomActionsModalClose();
        document.getElementById("mainModal").style.opacity = "1";
    }

    const addToSelectedActionsToAll = (e) => {
        let newItems = [];
        ticketData[accountID].forEach((ticket) => {
            if (!(selectedActions.filter(item => item.id === ticket.id).length > 0)) {
                newItems.push({"id": ticket.id, "action": e.target.value});
                document.getElementById("action-"+ticket.id).value = e.target.value;
            }
        })
        setSelectedActions(selectedActions.concat(newItems));
    }


    var stylesHeaders = {
        margin: '10px',
        float: 'left',
        fontWeight: 'bold',
    };

    var stylesContent = {
        margin: '10px',
        float: 'left',
    };

    var stylesHeadersRight = {
        margin: '10px',
        float: 'right',
        fontWeight: 'bold',
    };

    var stylesContentRight = {
        margin: '10px',
        float: 'right',
    };

    let queueDataHeader = null;

    if (props.user && props.queueActions &&  accountID  &&  ticketData) {

        const totalAmount = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(ticketData[accountID].total_amount);
        const doctorName = ticketData[accountID][0].doctor_full_name;

        queueDataHeader = (
            <>
                <table style={{width: '90vw', margin: '50px'}}><tbody>
                <tr>
                    <td><div style={stylesHeaders}>Doctor</div></td>
                    <td><div style={stylesHeaders}>Amount</div></td>
                    <td><div style={stylesHeaders}></div></td>
                </tr>
                <tr>
                    <td><div style={stylesContent}>{doctorName}</div></td>
                    <td><div style={stylesContent}>{totalAmount}</div></td>
                    <td><div style={stylesContent}><Button  onClick={openNotes}>Notes</Button></div></td>
                </tr>
                </tbody></table></>
        );
    }





    const handleTimePickerChange = (m) => {
        setInitialMoment(m);
    };

    const handleSave = () => {
        //find how many seconds from now the time is
        let currentTime = moment();
        let differenceInMilliseconds = initialMoment.diff(currentTime)
        //attach it to the array with the ticket information
        let tempActions = selectedActions.map((action) => {
            if (action.id == currentTicketID) {
                action.park_for_time_seconds = differenceInMilliseconds;
            }
            return action;
        });
        setSelectedActions(tempActions);
        //close window
        setShowCustomActionsModal(false);
        document.getElementById("mainModal").style.opacity = "1";
    };

    let queueDataComponent = null;


    //get all data for account
    if(props.user && !allRecords && accountID) {
        axios('api/ticketing/task/account/'+accountID).then(res => {
            setAllRecords(res.data);
            const initialData = res.data;
            const summarizedTableData = [];

            initialData.forEach((ticket) => {
                let acccountProcedures = initialData.filter(val => {
                    return val.org_account_id == ticket.org_account_id;
                });
                const totalAmountForAccountreducer = (accumulator, currentValue) => accumulator + currentValue;
                const totalAmountForAccount = acccountProcedures.reduce((accumulator, currentValue, currentIndex, array) => {
                    return accumulator + parseFloat(currentValue.amount)
                }, 0)
                uniqueAccountIDs[ticket.org_account_id] = acccountProcedures;
                uniqueAccountIDs[ticket.org_account_id]["total_amount"] = totalAmountForAccount.toFixed(2);
            });
            setTicketData(uniqueAccountIDs);
        });
    }


    if (props.user  && props.queueActions  && initialMoment && allRecords && accountID) {
       queueDataComponent = (
            <>
                <Accordion>
                    <table style={{width: '90vw', margin: '20px'}}><tbody>
                    <tr>
                        <td></td>
                        <td><div style={stylesHeaders}>Patient Code</div></td>
                        <td><div style={stylesHeaders}>Description</div></td>
                        <td><div style={stylesHeaders}>Tooth</div></td>
                        <td><div style={stylesHeaders}>Amount</div></td>
                        <td><div style={stylesHeaders}>Current Queue</div></td>
                        <td><div style={stylesHeaders}>Action</div></td>
                    </tr>
                    {allRecords.map((ticket, index) => {
                        let setCurrentMomentSingleVariable = currentMoment[ticket.id];
                        let totalAmount = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(ticket.amount);
                        return <><tr>
                            <td>
                                {/* <Button onClick={() => setOpen(!open)} aria-controls={"collapse-"+ticket.id} aria-expanded={open} >
                                    <Icon.ArrowDown color="black" size={32} />
                            </Button>*/}
                                <Accordion.Toggle as={Button} variant="link" eventKey={ticket.id}>
                                    <Icon.Plus color="black" size={32} />
                                </Accordion.Toggle>

                            </td>
                            <td><div style={stylesContent}>{ticket.patient_code}</div></td>
                            <td><div style={stylesContent}>{ticket.procedure_description}</div></td>
                            <td><div style={stylesContent}>{ticket.tooth}</div></td>
                            <td><div style={stylesContent}>{totalAmount}</div></td>
                            <td><div style={stylesContent}>{ticket.ticket.ticket_queue.name}</div></td>
                            <td><div style={stylesContent}>
                            <span className="custom-dropdown">
                            <select style={{"width" : "200px"}} name="queueActions" id={"action-"+ticket.id} onChange={(e) => addToSelectedActions(ticket.id, e)}>
                                <option>Select an Action</option>
                                {ticket.ticket.ticket_queue.actions.map((action, index) => {
                                    return <option key={index} id={action.id} data-header-name={action.name} value={action.id}>{action.name}</option>
                                })}
                                </select>
                               </span>
                            </div></td>

                        </tr>
                            <tr><td colSpan="6">
                                <Accordion.Collapse eventKey={ticket.id}>
                                    <Card.Body>
                                        <div style={stylesContent}><strong>Diagnosis Date: </strong>{  moment(ticket.diagnosis_date).local().format("MM/DD/YYYY")  } </div>
                                        <div style={stylesContent}><strong>Procedure Code: </strong>{ ticket.procedure_code }</div>
                                        <div style={stylesContent}><strong>Next Action At: </strong>{  moment(ticket.next_action_at).local().format("MM/DD/YYYY") } </div>
                                    </Card.Body>

                                </Accordion.Collapse>
                            </td></tr>
                        </>
                    })}
                    </tbody></table></Accordion>



            </>
        );
    }


    if (props.user && accountNotes.length==0 && accountID) {
        //get Account Notes
        axios.get('api/notes/'+accountID)
            .then(res => {
                if (res.data.length>0) {
                    setAccountNotes(res.data);
                } else {
                    let newItems = [{"note":"No Notes Entered Yet."}];
                    setAccountNotes(newItems);
                }
            }).catch(error => {
        })
    }

    let accountNotesComponent = null;

    if (accountNotes.length!=0)
        accountNotesComponent = (
            <>
                <div><strong>Notes:</strong><br />
                    {accountNotes.map((note, index) => {
                        return <>
                            {note.note}<br />
                        </>
                    })}
                </div>
                <br /><strong>Add Note:</strong>
                <form id='addNote' onSubmit={event => {event.preventDefault()}}>
                    <textarea rows="4" cols="50" name="notes" onChange={handleNoteChange}>
                    </textarea>
                    <br />
                    <button className="btn btn-success" onClick={updateNotes} >Save Note</button></form>
            </>
        );

    function formatPhoneNumber (str) {
        //Filter only numbers from the input
        let cleaned = ('' + str).replace(/\D/g, '');

        //Check if the input is of correct length
        let match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);

        if (match) {
            return '(' + match[1] + ') ' + match[2] + '-' + match[3]
        };

        return null
    };

    if (!queueDataComponent || !props.user || !initialMoment || !accountID) {
        return (

            <div className="flex-large account-search-input" >
                <Modal.Header closeButton>
                    <Modal.Title>Account Search Page</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Enter Account ID: <input type="text" value={accountIDInputValue} onChange={e => {setAccountIDInputValue(e.target.value)}} size="10" style={{ textAlign: 'center'}} />   <button className="btn btn-success" onClick={updateAccountSearch} >Search for Procedures</button> <div style={{color: "red", paddingLeft: '20px', display: 'inline-block'}}>{accountSearchMessage}</div>
                </Modal.Body>
                <Modal.Footer>
                    <button className="btn btn-success" onClick={updateRecords} >Save Updates</button>
                </Modal.Footer>
            </div>

        )
    } else {
        return (
            <>

                <div className="flex-large" id="mainModal" >

                    <Modal.Header closeButton>
                        <Modal.Title>Account Details - <input type="text" value={accountIDInputValue} onChange={e => {setAccountIDInputValue(e.target.value)}} size="10" style={{ textAlign: 'center'}} />   <button className="btn btn-success" onClick={updateAccountSearch} >Search for Procedures by Account ID</button><div style={{color: "red", display: 'inline-block', paddingLeft: '20px'}}>{accountSearchMessage}</div></Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <h2>{fullName} - {formatPhoneNumber(phone)}</h2>
                        {queueDataHeader}

                        {queueDataComponent}
                    </Modal.Body>
                    <Modal.Footer>
                        <button className="btn btn-success" onClick={updateRecords} >Save Updates</button>
                    </Modal.Footer>

                </div>

                <Modal show={showCustomActionsModal} onHide={closeCustomActions} className="custom-fields-modal">
                    <Modal.Header closeButton>
                        <Modal.Title>Custom Action Fields</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="custom-fields-modal-body">
                        Date of Next Action: <DatetimePickerTrigger
                        moment={initialMoment}
                        onChange={(e) => handleTimePickerChange(e)}
                        showTimePicker={false}
                        minDate={initialMoment}>
                        <input type="text" value={initialMoment.format('MM/DD/YYYY')} readOnly size="10" style={{ textAlign: 'center'}} />
                    </DatetimePickerTrigger>
                        {props.currentTicketID}

                    </Modal.Body>
                    <Modal.Footer>
                        <Button className="btn btn-success"  onClick={handleSave}>Update and Close</Button>
                    </Modal.Footer>
                </Modal>


                <Modal show={showAccountNotesModal} onHide={closeNotes} className="custom-fields-modal">

                    <Modal.Header closeButton>
                        <Modal.Title>Account Notes</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="custom-fields-modal-body">
                        {accountNotesComponent}
                    </Modal.Body>
                    <Modal.Footer>

                    </Modal.Footer>
                </Modal>

            </>
        )
    }
}

export default AccountSearch