import React, { useState, useEffect, useRef, Suspense, useCallback } from 'react';
import ReactDOM from 'react-dom'
import { Redirect } from "react-router-dom";
import axios from 'axios';
import styled from 'styled-components'
import {
    Container, Row, Col, Spinner, Form, FormControl, InputGroup, Button, Nav, Navbar, NavDropdown, Card, Modal
} from 'react-bootstrap';
import { useTable, useFilters, useGlobalFilter, useRowSelect, useSortBy, usePagination, } from 'react-table'
import * as Icon from 'react-bootstrap-icons';
import matchSorter from 'match-sorter'
import moment from 'moment'
import ViewLog from '../Records/ViewLog'
import ViewQueue from './ViewQueue'
import AccountSearch from './AccountSearch'
import ReactTable from 'react-table-6'
import 'react-table-6/react-table.css'
import TableFilter from 'react-table-filter';
import 'react-table-filter/lib/styles.css';
import CustomFields from './CustomFields'



const Styles = styled.div`
  padding: 1rem;

  table {
    border-spacing: 0;
    border: 1px solid black;

    tr {
      :last-child {
        td {
          border-bottom: 0;
        }
      }
    }

    th,
    td {
      margin: 0;
      padding: 0.5rem;
      border-bottom: 1px solid black;
      border-right: 1px solid black;

      :last-child {
        border-right: 0;
      }
    }
  }
`




const WorkQueue = (props) => {

    const initialQueueValue = [{ id: 0, value: " Select A Queue " }];
    const [data, setData] = useState([]);
    const [tableData, setTableData] = useState([]);
    const [uniqueTicketQueueIDs, setUniqueTicketQueueIDs] = useState(null);
    const [queues, setQueues] = useState(null);
    const [queueActions, setQueueActions] = useState([]);
    const [queueNames, setQueueNames] = useState({ id: 0, name: "" });
    const [selectedQueue, setSelectedQueue] = useState(null);
    const [pageCount, setPageCount] = React.useState(0)
    const [dataLoading, setDataLoading] = React.useState(false)
    const [dataLoaded, setDataLoaded] = React.useState(false)
    const initialFormState = { id: null, name: '', address1: '', address2: '', city: '', state: '', zip: '', phone: '', owner_name: '', owner_email: '' }
    const [tickets, setTickets] = useState(null);
    const [currentTicket, setCurrentTicket] = useState(initialFormState);
    const [queueTypeByRow, setQueueTypeByRow] = useState({ id: 0, value: 0 })
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const handleViewQueueClose = () => setShowViewQueueModal(false);
    const handleViewQueueShow = () => setShowViewQueueModal(true);
    const handleAccountSearchClose = () => setShowAccountSearchModal(false);
    const handleAccountSearchShow = () => setShowAccountSearchModal(true);
    const [show, setShow] = useState(false);
    const [showViewQueueModal, setShowViewQueueModal] = useState(false);
    const [showAccountSearchModal, setShowAccountSearchModal] = useState(false);
    const [accountID, setAccountID] = useState(0);
    const [fullName, setFullName] = useState(0);
    const [phone, setPhone] = useState(0);
    const [showSpinner, setShowSpinner] = useState(true);
    const [ticketID, setTicketID] = useState(0);
    const [ticketData, setTicketData] = useState(0);
    const uniqueAccountIDs = [];
    const [searchInput, setSearchInput] = useState(0);
    const [originalData, setOriginalData] = useState([]);
    const [callingQueueID, setCallingQueueID] = useState(0);
    const [defaultPageSize, setDefaultPageSize] = useState(0);
    const handleCustomFieldsClose = () => setShowCustomFieldsModal(false);
    const handleCustomFieldsShow = () => setShowCustomFieldsModal(true);
    const [showCustomFieldsModal, setShowCustomFieldsModal] = useState(false);
    const [currentTicketID, setCurrentTicketID] = React.useState(false);

    if (props.user && !queues) {
        props.toggleLoading(true);
        axios('api/ticketing/queue?per_page=100').then(res => {
            props.toggleLoading(false);
            let mappedQueues = [];
            res.data.data.forEach((queue) => {
                mappedQueues[queue.id] = queue;
            })
            setQueues(mappedQueues)
        });
    }

    const toggleSpinner = (loading) => {
        setShowSpinner(loading);
    }


    function multiDimensionalUnique(arr) {
        var uniques = [];
        var itemsFound = {};
        for (var i = 0, l = arr.length; i < l; i++) {
            var stringified = JSON.stringify(arr[i]);
            if (itemsFound[stringified]) { continue; }
            uniques.push(arr[i]);
            itemsFound[stringified] = true;
        }
        return uniques;
    }

    const getQueueData = (queue) => {
        toggleSpinner(true);
        axios.get('api/ticketing/queue/' + queue)
            .then(res => {
                props.toggleLoading(false);
                const initialData = res.data.tickets;
                const summarizedTableData = [];

                initialData.forEach((ticket) => {
                    let acccountProcedures = initialData.filter(val => {
                        return val.procedure_task.org_account_id == ticket.procedure_task.org_account_id;
                    });

                    const totalAmountForAccountreducer = (accumulator, currentValue) => accumulator + currentValue;
                    const totalAmountForAccount = acccountProcedures.reduce((accumulator, currentValue, currentIndex, array) => {
                        return accumulator + parseFloat(currentValue.procedure_task.amount)
                    }, 0)
                    uniqueAccountIDs[ticket.procedure_task.org_account_id] = acccountProcedures;
                    uniqueAccountIDs[ticket.procedure_task.org_account_id]["total_amount"] = totalAmountForAccount.toFixed(2);

                    summarizedTableData.push({ "next_action_at": ticket.next_action_at, "account_id": ticket.procedure_task.org_account_id, "total_amount": totalAmountForAccount, "doctor_name": acccountProcedures[0].procedure_task.doctor_full_name, "full_name": acccountProcedures[0].procedure_task.full_name, "phone": acccountProcedures[0].procedure_task.phone });

                });

                //only get unique account IDs and summation of dollar amount
                let filteredTableData = multiDimensionalUnique(summarizedTableData);
                setTableData(filteredTableData);
                setOriginalData(filteredTableData);

                setData(res.data.tickets);

                setQueueActions(res.data.actions);
                setTicketData(uniqueAccountIDs);
                setDataLoading(false);
                toggleSpinner(false);
            })
    }

    let queueComponent = null;



    const changeSelectedQueue = (e) => {
        if (!queues) {
            return;
        }

        let queueId = e.target.value;
        setSelectedQueue(queueId);
        getQueueData(queueId);

    }

    if (props.user && queues && callingQueueID) {
        queueComponent = (
            <span className="custom-dropdown">
                <select name="type" id="type" defaultValue={callingQueueID} onChange={(e) => changeSelectedQueue(e)}>
                    <option key="0" value="0"> Select A Queue </option>
                    {queues.map((queue, index) => {
                        if (queue.name == "Calling") {
                            return <option key={index} value={queue.id} > {queue.name} < /option>
                } else {
                        return <option key={index} value={queue.id} > {queue.name} < /option>
                        }
                    })}
    </select>
        </span>
    );


                    }
                
    if (props.user && queues  && !callingQueueID) {
                        let currentOrgCallingQueueID = queues.filter(val => {
            return val.name == "Calling";
                });
        
        if (currentOrgCallingQueueID[0]) {
                        setCallingQueueID(currentOrgCallingQueueID[0].id)
            setSelectedQueue(currentOrgCallingQueueID[0].id);
                    getQueueData(currentOrgCallingQueueID[0].id);
                }
            }
        
        
            // Define a default UI for filtering
    function GlobalFilter({
                        preGlobalFilteredRows,
                    globalFilter,
                    setGlobalFilter,
                          }) {
        const count = preGlobalFilteredRows.length
            
                    return (
            <span>
                        Search:{' '}
                        <input
                            value={globalFilter || ''}
                            onChange={e => {
                                setGlobalFilter(e.target.value || undefined) // Set undefined to remove the filter entirely
                            }}
                            placeholder={`${count} records...`}
                            style={{
                                fontSize: '1.1rem',
                                border: '0',
                            }}
                        />
                    </span>
                    )
                    }
                
                
                
                    // Define a default UI for filtering
    function DefaultColumnFilter({
                        column: {filterValue, preFilteredRows, setFilter },
                                 }) {
        const count = preFilteredRows.length
            
                    return (
            <input
                        value={filterValue || ''}
                        onChange={e => {
                            setFilter(e.target.value || undefined) // Set undefined to remove the filter entirely
                        }}
                        placeholder={`Search ${count} records...`}
                    />
                    )
                    }
                
                    // This is a custom filter UI for selecting
                // a unique option from a list
    function SelectColumnFilter({
                        column: {filterValue, setFilter, preFilteredRows, id },
                                }) {
        // Calculate the options for filtering
        // using the preFilteredRows
        const options = React.useMemo(() => {
            const options = new Set()
            preFilteredRows.forEach(row => {
                        options.add(row.values[id])
                    })
                    return [...options.values()]
                }, [id, preFilteredRows])
        
                // Render a multi-select box
                return (
            <select
                        value={filterValue}
                        onChange={e => {
                            setFilter(e.target.value || undefined)
                        }}
                    >
                        <option value="">All</option>
                        {options.map((option, i) => (
                            <option key={i} value={option}>
                                {option}
                            </option>
                        ))}
                    </select>
                    )
                    }
                
                // This is a custom filter UI that uses a
                // slider to set the filter value between a column's
                // min and max values
    function SliderColumnFilter({
                        column: {filterValue, setFilter, preFilteredRows, id },
                                }) {
        // Calculate the min and max
        // using the preFilteredRows

        const [min, max] = React.useMemo(() => {
                        let min = preFilteredRows.length ? preFilteredRows[0].values[id] : 0
                    let max = preFilteredRows.length ? preFilteredRows[0].values[id] : 0
            preFilteredRows.forEach(row => {
                        min = Math.min(row.values[id], min)
                max = Math.max(row.values[id], max)
                })
                return [min, max]
            }, [id, preFilteredRows])
    
            return (
            <>
                        <input
                            type="range"
                            min={min}
                            max={max}
                            value={filterValue || min}
                            onChange={e => {
                                setFilter(parseInt(e.target.value, 10))
                            }}
                        />
                        <button onClick={() => setFilter(undefined)}>Off</button>
                    </>
                    )
                    }
                
                // This is a custom UI for our 'between' or number range
                // filter. It uses two number boxes and filters rows to
                // ones that have values between the two
    function NumberRangeColumnFilter({
                        column: {filterValue = [], preFilteredRows, setFilter, id },
                                     }) {
        const [min, max] = React.useMemo(() => {
                        let min = preFilteredRows.length ? preFilteredRows[0].values[id] : 0
                    let max = preFilteredRows.length ? preFilteredRows[0].values[id] : 0
            preFilteredRows.forEach(row => {
                        min = Math.min(row.values[id], min)
                max = Math.max(row.values[id], max)
                })
                return [min, max]
            }, [id, preFilteredRows])
    
            return (
            <div
                        style={{
                            display: 'flex',
                        }}
                    >
                        <input
                            value={filterValue[0] || ''}
                            type="number"
                            onChange={e => {
                                const val = e.target.value
                                setFilter((old = []) => [val ? parseInt(val, 10) : undefined, old[1]])
                            }}
                            placeholder={`Min (${min})`}
                            style={{
                                width: '70px',
                                marginRight: '0.5rem',
                            }}
                        />
                        to
        <input
                            value={filterValue[1] || ''}
                            type="number"
                            onChange={e => {
                                const val = e.target.value
                                setFilter((old = []) => [old[0], val ? parseInt(val, 10) : undefined])
                            }}
                            placeholder={`Max (${max})`}
                            style={{
                                width: '70px',
                                marginLeft: '0.5rem',
                            }}
                        />
                    </div>
                    )
                    }
                
    function fuzzyTextFilterFn(rows, id, filterValue) {
        return matchSorter(rows, filterValue, {keys: [row => row.values[id]] })
                }
            
    function printPage() {
                        window.print();
                    }
                
    const handleCheckboxClick = (e, row) => {
                        toggleRecordCheck(row.id);
                    }
                
    const toggleRecordCheck = (rowId) => {
                        let tempRecords = data.map((record) => {
            if (record.id == rowId) {
                        let checked = false;
                if (record.checked != true) {
                        checked = true;
                    }
                    record.checked = checked;
                }
                return record;
            });
            setData(tempRecords);
        }
    
    const updateRecord = (e, row) => {
                        axios.get('api/ticketing/ticket/action/' + row.id + '/' + e.target.value)
                            .then(res => {

                                setData(data.filter((record) => res.data.id != record.id));
                            }).catch(error => {
                                console.log(error.message);
                            })
                    }

                    const showViewLog =  (rowId) => {
                        setTicketID(rowId);
                    handleShow()
                }
            
    const showViewQueue =  (accountID, fullName, phone) => {
                        setAccountID(accountID);
                    setFullName(fullName);
                    setPhone(phone);
            
                    handleViewQueueShow()
                }
            
    const ShowAccountSearch =  () => {
                        //setAccountID(accountID);
                        handleAccountSearchShow()
                    }


                    function formatPhoneNumber (str) {
                        //Filter only numbers from the input
                        let cleaned = ('' + str).replace(/\D/g, '');
                    //Check if the input is of correct length
        let match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
        if (match) {
        return '(' + match[1] + ') ' + match[2] + '-' + match[3]
                    };
                    return null
                };
            
            
                        // Let the table remove the filter if the string is empty
                fuzzyTextFilterFn.autoRemove = val => !val
            
                const columns = React.useMemo(() => [
    {
                        Header: '',
                    id: 10,
                    fixed: "left",
                    maxWidth: 75,
                    filterable: false,
                    resizable: false,
                    Cell: row => (
        <div style={{ cursor: "pointer" }}>
                        <Icon.Window color="black" size={32} onClick={() => showViewQueue(row.row.account_id, row.row.full_name, row.row.phone)} />
                    </div>
                    )
                },
          /*  {Header: 'Action',
                              fixed: "left",
                    Cell: ({row}) => (
                <select style={{ "width": "200px" }} name="queueActions" onChange={(e) => updateRecord(e, row)}>
                        <option>Select an Action</option>
                        {queueActions.map((action, index) => {
                            return <option key={index} id={action.id} value={action.id}>{action.name}</option>
                        })}
                    </select>
                    )},*/
    {Header: 'Account ID', accessor: 'account_id',  filterable: false, },
    {Header: 'PT Name', accessor: 'full_name',  filterable: false, },
    {Header: 'Next Action', accessor: 'next_action_at',  filterable: false,
    Cell: props => {
        return moment(props.value).local().format("MM/DD/YYYY");
                }, 
    } ,
    {Header: 'Phone', accessor: 'phone', Cell: props => { return formatPhoneNumber(props.value)}, filterable: false, },
    {Header: 'Amount', accessor: 'total_amount',  filterable: false,
        Cell: props => {
        return new Intl.NumberFormat('en-US', {style: 'currency', currency: 'USD' }).format(props.value);
                },
             },
            
    {Header: 'Doctor', accessor: 'doctor_name',
                                    filterable: false, },
                    ],
                    [queues, tableData, queueActions, ticketData]
                  )
                
    function Table({columns, data }) {
        // Use the state and functions returned from useTable to build your UI


        const filterTypes = React.useMemo(
            () => ({
                        // Add a new fuzzyTextFilterFn filter type.
                        fuzzyText: fuzzyTextFilterFn,
                    // Or, override the default text filter to use
                    // "startWith"
                text: (rows, id, filterValue) => {
                    return rows.filter(row => {
                        const rowValue = row.values[id]
                    return rowValue !== undefined
                        ? String(rowValue)
                            .toLowerCase()
                            .startsWith(String(filterValue).toLowerCase())
                        : true
                })
            },
        }),
        []
    )




    const defaultColumn = React.useMemo(
            () => ({
                        // Let's set up our default Filter UI
                        Filter: DefaultColumnFilter,
                }),
                []
            )
    
        const {
                        getTableProps,
                    getTableBodyProps,
                    headerGroups,
                    prepareRow,
                    state,
                    flatColumns,
                    preGlobalFilteredRows,
                    setGlobalFilter,
                    selectedFlatRows,
                    page,
                    canPreviousPage,
                    canNextPage,
                    pageOptions,
                    pageCount,
                    gotoPage,
                    nextPage,
                    previousPage,
                    setPageSize,
            state: {pageIndex, pageSize },
            state: {selectedRowIds},
                } = useTable(
            {
                        columns,
                    data,
                    defaultColumn, // Be sure to pass the defaultColumn option
                    filterTypes,
                initialState: {pageIndex: 0 }, // Pass our hoisted table state
                },
    
                useFilters, // useFilters!
                useGlobalFilter, // useGlobalFilter!
                useSortBy,
                usePagination,
            useRowSelect,
    
            )
            // Render the UI for your table
    
    
            // Listen for changes in pagination and use the state to fetch our new data
        /*React.useEffect(() => {
                        fetchData({ pageIndex, pageSize })
                    }, [fetchData, pageIndex, pageSize])
            */
            
                    return (
            <>
                        <table style={{ width: '100%' }} {...getTableProps()}>
                            <thead>
                                {headerGroups.map(headerGroup => (
                                    <tr {...headerGroup.getHeaderGroupProps()}>
                                        {headerGroup.headers.map(column => (
                                            <th {...column.getHeaderProps()}>
                                                {/* Render the columns filter UI */}
                                                <div> <span {...column.getSortByToggleProps()}>
                                                    {column.render('Header')}
                                                    {/* Add a sort direction indicator */}
                                                    {column.isSorted
                                                        ? column.isSortedDesc
                                                            ? ' 🔽'
                                                            : ' 🔼'
                                                        : ''}
                                                </span></div>
                                                <div>{column.canFilter ? column.render('Filter') : null}
                                                </div>
                                            </th>
                                        ))}
                                    </tr>
                                ))}
                                <tr>
                                    <th
                                        colSpan={flatColumns.length}
                                        style={{
                                            textAlign: 'left',
                                        }}
                                    >
                                        <GlobalFilter
                                            preGlobalFilteredRows={preGlobalFilteredRows}
                                            globalFilter={state.globalFilter}
                                            setGlobalFilter={setGlobalFilter}
                                        />
                                    </th>
                                </tr>
                            </thead>
                            <tbody {...getTableBodyProps()}>
                                {page.map(
                                    (row, i) => {
                                        prepareRow(row);
                                        return (
                                            <tr {...row.getRowProps()}>
                                                {row.cells.map(cell => {
                                                    return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                                                })}
                                            </tr>
                                        )
                                    }
                                )}
                            </tbody>
                        </table>
                        {/*
        Pagination can be built however you'd like.
        This is just a very basic UI implementation:
      */}
                        <div className="pagination">f
            <button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
                                {'<<'}
                            </button>{' '}
                            <button onClick={() => previousPage()} disabled={!canPreviousPage}>
                                {'<'}
                            </button>{' '}
                            <button onClick={() => nextPage()} disabled={!canNextPage}>
                                {'>'}
                            </button>{' '}
                            <button onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
                                {'>>'}
                            </button>{' '}
                            <span>
                                Page{' '}
                                <strong>
                                    {pageIndex + 1} of {pageOptions.length}
                                </strong>{' '}
                            </span>
                            <span>
                                | Go to page:{' '}
                                <input
                                    type="number"
                                    defaultValue={pageIndex + 1}
                                    onChange={e => {
                                        const page = e.target.value ? Number(e.target.value) - 1 : 0
                                        gotoPage(page)
                                    }}
                                    style={{ width: '100px' }}
                                />
                            </span>{' '}
                            <select
                                value={pageSize}
                                onChange={e => {
                                    setPageSize(Number(e.target.value))
                                }}
                            >
                                {[10, 20, 30, 40, 50].map(pageSize => (
                                    <option key={pageSize} value={pageSize}>
                                        Show {pageSize}
                                    </option>
                                ))}
                            </select>
                        </div>
                    </>
                    )
                    }
                
                    // Define a custom filter filter function!
    function filterGreaterThan(rows, id, filterValue) {
        return rows.filter(row => {
            const rowValue = row.values[id]
                    return rowValue >= filterValue
                })
            }
        
    function handleSearchChange(event) {
                        setSearchInput(event.target.value);
                    };
                
    useEffect(() => {
                        globalSearch();
                    }, [searchInput]);
                
    function globalSearch () {
                        let filteredData = originalData.filter(value => {
        return (
                        value.account_id.toString().toLowerCase().includes(searchInput.toLowerCase()) ||
                        value.total_amount.toString().toLowerCase().includes(searchInput.toLowerCase()) ||
                        value.doctor_name.toString().toLowerCase().includes(searchInput.toLowerCase()) ||
                         value.full_name.toString().toLowerCase().includes(searchInput.toLowerCase()) ||
                                    value.phone.toString().toLowerCase().includes(searchInput.toLowerCase())
                        );
                    });
                    setTableData(filteredData);
                };
            
                filterGreaterThan.autoRemove = val => typeof val !== 'number'
            
            
                    return (
            <Container fluid={true}>
                        <Row style={{ width: '95vw' }}>
                            <Col>
                                <Card className="content-card">
                                    <Card.Header>
                                        Work Queue {showSpinner && <Spinner animation="border" role="status" size="sm"></Spinner>}

                                    </Card.Header>
                                    <Card.Body>
                                        <Row>
                                            <div style={{ float: 'left', marginLeft: '30px', marginBottom: '10px' }}>
                                                {queueComponent}
                                            </div>
                                            <div style={{ float: 'left', marginLeft: '20px', marginTop: '5px', marginBottom: '10px' }}>Search: <input
                                                size="large"
                                                name="searchInput"
                                                value={searchInput || ""}
                                                onChange={handleSearchChange}
                                                label="Search"
                                            />
                                            </div>

                                            <button className="btn btn-success" style={{ float: 'right', marginLeft: '20px', marginBottom: '10px' }} onClick={() => printPage()}>Print</button>
                                            <button className="btn btn-success" style={{ float: 'right', marginLeft: '20px', marginBottom: '10px' }} onClick={() => ShowAccountSearch()}>Account Search</button>

                                        </Row>
                                        {/*<Styles>
            <Table
                columns={columns}
                data={tableData}
            />
        </Styles>*/}
                                        <div style={{ float: 'none', clear: 'left', textAlign: 'center', paddingTop: '15px' }}>
                                            <ReactTable
                                                data={tableData}
                                                columns={columns}
                                                filterable={true}
                                                minRows={10}
                                                defaultPageSize={10}
                                            />
                                        </div>
                                        <div style={{ width: '100%', float: 'left', fontStyle: 'italic' }}>* You can sort any column by clicking on the header text.</div>
                                        <Modal key="view-log-modal" show={show} onHide={handleClose} dialogClassName="view-log-modal">
                                            <ViewLog key="view-log" ticketID={ticketID}  {...props} />
                                        </Modal>
                                        <Modal key="view-queue-modal" show={showViewQueueModal} onHide={handleViewQueueClose} dialogClassName="view-queue-modal">
                                            <ViewQueue key="view-queue" showViewQueueModal={showViewQueueModal} callingQueueID={callingQueueID} ticketData={ticketData} accountID={accountID} fullName={fullName} phone={phone} handleViewQueueClose={handleViewQueueClose} queueActions={queueActions} getQueueData={getQueueData} tableData={tableData} setTableData={setTableData} setShowCustomFieldsModal={setShowCustomFieldsModal} selectedQueue={selectedQueue}  {...props} />
                                        </Modal>

                                        <Modal key="account-search-modal" show={showAccountSearchModal} onHide={handleAccountSearchClose} dialogClassName="account-search-modal">
                                            <AccountSearch key="account-search" showAccountSearchModal={showAccountSearchModal} callingQueueID={callingQueueID} ticketData={ticketData} accountID={accountID} fullName={fullName} phone={phone} handleAccountSearchClose={handleAccountSearchClose} queueActions={queueActions} getQueueData={getQueueData} tableData={tableData} setTableData={setTableData} setShowCustomFieldsModal={setShowCustomFieldsModal}  {...props} />
                                        </Modal>


                                        {/*  <Modal key="custom-fields-modal" show={showCustomFieldsModal} onHide={handleCustomFieldsClose} dialogClassName="custom-fields-modal">
            <CustomFields key="custom-fields" {...props} />
        </Modal>*/}
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                    </Container>

                    );
                }
                
                
                
                
export default WorkQueue;