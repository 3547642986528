import React from 'react';
import {
  Spinner
} from 'react-bootstrap';
import './Loading.css';



const Loading = (props) => {

    return (
      <>
        { props.loading && 
          <div className='loading'>
            <div className='loading-content'>
              <Spinner animation="border" />
              <p>Loading...</p>
            </div>
          </div>
        }
      </>
    )
}

export default Loading;