import React, {useState} from 'react';
import logo from '../../logo.svg';
import {
    Container, Row, Col, Form, FormControl, InputGroup, Button, Nav, Navbar, NavDropdown
} from 'react-bootstrap';
import {LinkContainer} from 'react-router-bootstrap';

const Header = (props) => {
    if (!props.user) {
        return (
            <div></div>
    );
    } else {
    return (
        <header>
            <Navbar fixed="top" fluid="true" collapseOnSelect bg="light" expand="lg" className="mb-20"
                    data-offset-top="197">
                <Navbar.Brand href="/">Drive Dental Solutions</Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav"/>
                <Navbar.Collapse id="basic-navbar-nav">
                    {props.user &&
                    <Nav className="ml-auto">
                        {props.user.is_admin == 1 &&
                        < LinkContainer to="/organizations">
                          <Nav.Link>Organizations</Nav.Link>
                          < /LinkContainer>
                              }
                          {props.user.organization && (props.user.id == props.user.organization.owner_id) &&
                          < LinkContainer to="/organization">
                              <Nav.Link>My Organization</Nav.Link>
                              < /LinkContainer>
                                  }
                          {props.user.role_id != 3 && props.user.role_id != 4 &&
                          < LinkContainer to="/users">
                              <Nav.Link>Users</Nav.Link>
                              < /LinkContainer>
                                  }
                          {props.user.role_id != 3 && props.user.role_id != 4 &&
                          < LinkContainer to="/queues">
                              <Nav.Link>Queues</Nav.Link>
                              < /LinkContainer>
                                  }
                          {props.user.role_id != 3 && props.user.role_id != 4 &&
                          < LinkContainer to="/records">
                              <Nav.Link>Records</Nav.Link>
                              < /LinkContainer>
                                  }
                            < LinkContainer to="/work-queue">
                                <Nav.Link>Work Queue</Nav.Link>
                                < /LinkContainer>
                            {props.user.role_id != 3 && props.user.role_id != 4 &&
                            < LinkContainer to="/upload">
                                <Nav.Link>Upload</Nav.Link>
                                < /LinkContainer>
                                                          }
                            < LinkContainer to="/account">
                                <Nav.Link>Account</Nav.Link>
                                < /LinkContainer>
                            < LinkContainer to="/reports">
                                <Nav.Link>Reports</Nav.Link>
                                < /LinkContainer>
                            <LinkContainer to="/logout">
                                <Nav.Link>Logout</Nav.Link>
                            </LinkContainer>
                                </Nav>
                                }
                                {!props.user &&
                                <Nav className="ml-auto">
                                    <LinkContainer to="/login">
                                        <Nav.Link>Login</Nav.Link>
                                    </LinkContainer>
                                </Nav>
                                }
                            </Navbar.Collapse>
                        </Navbar>
                    </header>
                        )
                    };
    }

export default Header;