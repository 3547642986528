import React, { useState } from 'react';
import axios from 'axios';
import {
    Container, Row, Col, Form, FormControl, InputGroup, Button, Nav, Navbar, NavDropdown, Card
} from 'react-bootstrap';
import { Redirect } from "react-router-dom";


const Account = (props) => {

    const [current_password, setCurrentPassword] = useState();
    const [password, setPassword] = useState();
    const [password_confirmation, setPasswordConfirmation] = useState();
    const [resetPasswordMessage, setResetPasswordMessage] = useState();
    const [currentUser, setCurrentUser] = useState();
    const [toHome, setToHome] = useState(false);
    const [toWorkQueue, setToWorkQueue] = useState(false);

    const account = (e) => {

        e.preventDefault();
        let data = {
            current_password: current_password,
            password: password,
            password_confirmation: password_confirmation,
        }
        axios.post('api/auth/password/change/'+props.user.id, data)
            .then(res => {
                window.token = res.data;
                if (res.data.status) {
                    setResetPasswordMessage(res.data.status);
                     props.setUser(null);
                     setToWorkQueue(true);
                }
                if (res.data.message) {
                    setResetPasswordMessage(res.data.message);
                }
            })
        .catch((err) => {
            if (err) {
                if (err.response.data.errors.password_confirmation) {
                    setResetPasswordMessage(err.response.data.errors.password_confirmation[0]);
                }
                console.log(err.response);
            }
            })
    }


    if (!props.user) {
        return (
            <div></div>
    );
    } else {
        return (
            <>
            {/*{toHome ? <Redirect to="/" /> : null}*/}
        {toWorkQueue ? <Redirect to="/login" /> : null}

    <Container>
        <Row>
        <Col>
        <Card className="content-card">
            <Card.Header>Account</Card.Header>
            <Card.Body>

            <Row>
            <Col sm="6" className="text-right">Your Email Address : </Col>
        <Col sm="6" className="text-left"> { (props.user ? props.user.email : "") }</Col>
        </Row>
        <Row>
        <Col sm="6" className="text-right">Your Organization : </Col>
        <Col sm="6" className="text-left">{ (props.user.organization ? props.user.organization.name : "") } </Col>
        </Row>
        <Row className="mb-3">
            <Col sm="6" className="text-right">Your Role : </Col>
        <Col sm="6" className="text-left"> { (props.user ? props.user.role.name : "") }</Col>
        </Row>
        <Row>
        <Col>
        <hr />
        </Col>
        </Row>

        <Form onSubmit={(e) => account(e)}>
    <Form.Group as={Row} controlId="formBasicEmail"  className="mt-3">
            <Form.Label column sm="4">Current Password</Form.Label>
        <Col sm="8">
            <Form.Control type="password" placeholder="" onChange={(e) => setCurrentPassword(e.target.value)} />
        </Col>
        </Form.Group>
        <Form.Group as={Row} controlId="formBasicPassword">
            <Form.Label column sm="4">Password</Form.Label>
            <Col sm="8">
            <Form.Control type="password" placeholder="" onChange={(e) => setPassword(e.target.value)} />
        </Col>
        </Form.Group>
        <Form.Group as={Row} controlId="formBasicPasswordConfirmation">
            <Form.Label column sm="4">Password Confirmation</Form.Label>
        <Col sm="8">
            <Form.Control type="password" placeholder="" onChange={(e) => setPasswordConfirmation(e.target.value)} />
        </Col>
        </Form.Group>
        <Button variant="primary" type="submit" >
            Change Password
        </Button>
        </Form>
        <div id="ResetPasswordMessage" style={{marginTop: 10 + 'px'}} >{ resetPasswordMessage }</div>
        <div id="ResetPasswordMessage" style={{marginTop: 10 + 'px'}} >After a successful Password change, you will be required to log in again with your new password.</div>

        </Card.Body>
        </Card>
        </Col>
        </Row>
        </Container>
        </>

    )
    }

}

export default Account;