import React, { useState } from 'react';
import axios from 'axios';
import {
  Container, Row, Col, Form, FormControl, InputGroup, Button, Nav, Navbar, NavDropdown, Card
} from 'react-bootstrap';
import { Redirect } from "react-router-dom";
import './Login.css';



const Login = (props) => {


  const [emailAddress, setEmailAddress] = useState();
  const [password, setPassword] = useState();
  const [toHome, setToHome] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);

  const login = (e) => {
    e.preventDefault();
    let data = {
      email: emailAddress,
      password: password,
    }
    axios.post('login', data)
    .then(res => {
        window.token = res.data;
        props.authTest();
        setToHome(true);
    }).catch((err) => {
      if (err) {
        if (err.response.data.errors.email) {
          setErrorMessage(err.response.data.errors.email[0]);
        }
        console.log(err.response);
      }
    });
  }

    return (
        <>
        {toHome ? <Redirect to="/" /> : null}
    <Container>
      <Row>
        <Col>
          <Card className="login-card content-card">
            <Card.Header>Login</Card.Header>
            <Card.Body>
              <div id="ErrorMessage" style={{color: 'red', marginBottom: 10 + 'px'}} >{ errorMessage }</div>
              <Form onSubmit={(e) => login(e)}>
                <Form.Group as={Row} controlId="formBasicEmail">
                  <Form.Label column sm="3">Email address</Form.Label>
                  <Col sm="9">
                    <Form.Control type="email" placeholder="name@example.com" onChange={(e) => setEmailAddress(e.target.value)} />
                  </Col>
                </Form.Group>
                <Form.Group as={Row} controlId="formBasicPassword">
                  <Form.Label column sm="3">Password</Form.Label>
                  <Col sm="9">
                    <Form.Control type="password" placeholder="Password" onChange={(e) => setPassword(e.target.value)} />
                  </Col>
                </Form.Group>
                <Button variant="primary" type="submit" >
                  Login
                </Button>
              </Form>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
        </>
)
}

export default Login;