import React, { useState, Component } from 'react'
import moment from 'moment';
import ReactDOM from "react-dom";
import "imrc-datetime-picker/dist/imrc-datetime-picker.css";
import {DatetimePickerTrigger} from 'imrc-datetime-picker';

import {
    Container, Collapse, Row, Col, Form, FormControl, InputGroup, Button, Nav, Navbar, NavDropdown, Card, Modal
} from 'react-bootstrap';
import axios from 'axios';
import { useTable } from 'react-table'
import styled from 'styled-components'
import './ViewQueue.css';
import * as Icon from 'react-bootstrap-icons';
import Accordion from 'react-bootstrap/Accordion'
import { useAccordionToggle } from 'react-bootstrap/AccordionToggle';


const Styles = styled.div`
  padding: 1rem;

  table {
    border-spacing: 0;
    border: 1px solid black;

    tr {
      :last-child {
        td {
          border-bottom: 0;
        }
      }
    }

    th,
    td {
      margin: 0;
      padding: 0.5rem;
      border-bottom: 1px solid black;
      border-right: 1px solid black;

      :last-child {
        border-right: 0;
      }
    }
  }
`

const useForceUpdate = () => useState()[1];

const ViewQueue = props => {

    const [logData, setLogData] = useState(null);
    const [dataLoading, setDataLoading] = React.useState(false)
    const [dataLoaded, setDataLoaded] = React.useState(false)
    const [users, setUsers] = React.useState(false)
    const [message, setMessage] = React.useState(false);
    const [currentTicketID, setCurrentTicketID] = React.useState(false);
    const [selectedActions, setSelectedActions] = React.useState([]);
    const [accountNotes, setAccountNotes] = React.useState([]);
    const [currentMoment, setCurrentMoment] = React.useState( []);
    const [currentMomentSingle, setCurrentMomentSingle] = React.useState( moment() );
    const [cleared, setCleared] = React.useState( false );
    const [initialMoment, setInitialMoment] = React.useState(moment());
    const [showCustomActionsModal, setShowCustomActionsModal] = React.useState(false);
    const [showAccountNotesModal, setShowAccountNotesModal] = React.useState(false);
    const [open, setOpen] = useState(false);
    const handleCustomActionsModalClose = () => setShowCustomActionsModal(false);
    const handleCustomActionsModalShow = () => setShowCustomActionsModal(true);
    const handleAccountNotesModalClose = () => setShowAccountNotesModal(false);
    const handleAccountNotesModalShow = () => setShowAccountNotesModal(true);
    const [newNotes, setNewNotes] = React.useState( false );

    const handleNoteChange = event => {
        const { name, value } = event.target
        setNewNotes(value);
    }


    const forceUpdate = useForceUpdate();
    const newMoment = moment();
    //const decoratedOnClick = useAccordionToggle(eventKey, onClick);

    const shortcuts = {
        'Today': newMoment,
    };


    let currentMessage = "";


    let queueActionComponent = null;

    const updateRecords  = () => {
       let totalRecords=0;
       let processedRecords=0;
       let data = {}
       selectedActions.forEach((action) => {
       totalRecords++
       if (action.park_for_time_seconds) {
           let data = {
               park_for_time_seconds: action.park_for_time_seconds
           }
       } else {
           let data = {}
       }

        axios.post('api/ticketing/ticket/action/'+ action.id + '/' +action.action, data)
            .then(res => {
                processedRecords++
                if (totalRecords==processedRecords) {
                    props.getQueueData(props.selectedQueue)
                    props.handleViewQueueClose();
                }
            });
        });
    }


    const updateNotes  = () => {

        let data = {
            note: newNotes
        }

        axios.post('api/note/'+ props.accountID, data)
            .then(res => {
                setAccountNotes([]);
            });

    }


    const addToSelectedActions = (id, e) => {
        const filteredActions = selectedActions.filter(item => item.id !== id);
        setSelectedActions(filteredActions.concat({"id": id, "action": e.target.value}));

        if (e.target.value==4) {
            setCurrentTicketID(id);
            handleCustomActionsModalShow();
            document.getElementById("mainModal").style.opacity = "0.1";
        }
    }

    const openNotes = () => {
        handleAccountNotesModalShow();
        document.getElementById("mainModal").style.opacity = "0.1";

    }

    const closeNotes = () => {
        handleAccountNotesModalClose();
        document.getElementById("mainModal").style.opacity = "1";
    }

    const closeCustomActions = () => {
        handleCustomActionsModalClose();
        document.getElementById("mainModal").style.opacity = "1";
    }

    const addToSelectedActionsToAll = (e) => {
        let newItems = [];
        props.ticketData[props.accountID].forEach((ticket) => {
            if (!(selectedActions.filter(item => item.id === ticket.id).length > 0)) {
                newItems.push({"id": ticket.id, "action": e.target.value});
                document.getElementById("action-"+ticket.id).value = e.target.value;
            }
        })
        setSelectedActions(selectedActions.concat(newItems));
    }


    var stylesHeaders = {
        margin: '10px',
        float: 'left',
        fontWeight: 'bold',
    };

    var stylesContent = {
        margin: '10px',
        float: 'left',
    };

    var stylesHeadersRight = {
        margin: '10px',
        float: 'right',
        fontWeight: 'bold',
    };

    var stylesContentRight = {
        margin: '10px',
        float: 'right',
    };

    let queueDataHeader = null;

    if (props.user && props.queueActions) {
        const totalAmount = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(props.ticketData[props.accountID].total_amount);
        const doctorName = props.ticketData[props.accountID][0].procedure_task.doctor_full_name;

        queueDataHeader = (
            <>
                <table style={{width: '90vw', margin: '20px'}}><tbody>
                    <tr>
                        <td width="25%"><div style={stylesHeaders}>Doctor</div></td>
                        <td width="25%"><div style={stylesHeaders}>Amount</div></td>
                        <td width="15%"><div style={stylesHeaders}></div></td>
                        <td width="35%"><div style={stylesHeadersRight}>Set Action for all Procedures</div></td>
                    </tr>
                    <tr>
                        <td><div style={stylesContent}>{doctorName}</div></td>
                        <td><div style={stylesContent}>{totalAmount}</div></td>
                        <td><div style={stylesContent}><Button  onClick={openNotes}>Notes</Button></div></td>
                            <td><div style={stylesContentRight}><span className="custom-dropdown">
                            <select style={{"width" : "300px"}} tabIndex="1" name="queueActionsHeader" onChange={(e) => addToSelectedActionsToAll(e)}>
                                <option>Select an Action</option>
                                 {props.queueActions.map((action, index) => {
                                    return <option key={index} id={action.id} data-header-name={action.name} value={action.id}>{action.name}</option>
                                })}
                            </select>
                            </span></div>
                    </td>



                    </tr>
                </tbody></table></>
        );
    }





    const handleTimePickerChange = (m) => {
        setInitialMoment(m);
    };

    const handleSave = () => {
        //find how many seconds from now the time is
        let currentTime = moment();
        let differenceInMilliseconds = initialMoment.diff(currentTime)
        //attach it to the array with the ticket information
        let tempActions = selectedActions.map((action) => {
            if (action.id == currentTicketID) {
                action.park_for_time_seconds = differenceInMilliseconds;
            }
            return action;
        });
        setSelectedActions(tempActions);
        //close window
        setShowCustomActionsModal(false);
        document.getElementById("mainModal").style.opacity = "1";
    };

    let queueDataComponent = null;


    if (props.user  && props.queueActions  && initialMoment) {
        queueDataComponent = (
            <>
                <Accordion>
                <table style={{width: '90vw', margin: '20px'}}><tbody>
                    <tr>
                        <td></td>
                        <td><div style={stylesHeaders}>Patient Code</div></td>
                        <td><div style={stylesHeaders}>Description</div></td>
                        <td><div style={stylesHeaders}>Tooth</div></td>
                        <td><div style={stylesHeaders}>Amount</div></td>
                        <td><div style={stylesHeadersRight}>Custom Action</div></td>
                    </tr>
                    {props.ticketData[props.accountID].map((ticket, index) => {

                        let setCurrentMomentSingleVariable = currentMoment[ticket.id];
                        let totalAmount = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(ticket.procedure_task.amount);
                        return <><tr>
                            <td>
                               {/* <Button onClick={() => setOpen(!open)} aria-controls={"collapse-"+ticket.id} aria-expanded={open} >
                                    <Icon.ArrowDown color="black" size={32} />
                            </Button>*/}
                                <Accordion.Toggle as={Button} variant="link" eventKey={ticket.id}>
                                    <Icon.Plus color="black" size={32} />
                                </Accordion.Toggle>

                            </td>
                            <td><div style={stylesContent}>{ticket.procedure_task.patient_code}</div></td>
                            <td><div style={stylesContent}>{ticket.procedure_task.procedure_description}</div></td>
                            <td><div style={stylesContent}>{ticket.procedure_task.tooth}</div></td>
                            <td><div style={stylesContent}>{totalAmount}</div></td>
                            <td><div style={stylesContentRight}>
                            <span className="custom-dropdown">
                            <select style={{"width" : "200px"}} name="queueActions" tabIndex={2} id={"action-"+ticket.id} onChange={(e) => addToSelectedActions(ticket.id, e)}>
                                <option>Select an Action</option>
                                    {props.queueActions.map((action, index) => {
                                        return <option key={index} id={action.id} data-header-name={action.name} value={action.id}>{action.name}</option>
                                    })}
                                </select>
                               </span>
                        </div></td>

                            
                        </tr>
                        <tr><td colSpan="6">
                            <Accordion.Collapse eventKey={ticket.id}>
                                <Card.Body>
                                     <div style={stylesContent}><strong>Diagnosis Date: </strong>{  moment(ticket.procedure_task.diagnosis_date).local().format("MM/DD/YYYY")  } </div>
                                <div style={stylesContent}><strong>Procedure Code: </strong>{ ticket.procedure_task.procedure_code }</div>
                                <div style={stylesContent}><strong>Next Action At: </strong>{  moment(ticket.next_action_at).local().format("MM/DD/YYYY") } </div>
                                </Card.Body>

                        </Accordion.Collapse>
                        </td></tr>
                        </>
                    })}
                </tbody></table></Accordion>



</>
        );
    }


    if (props.user && accountNotes.length==0) {
        //get Account Notes
        axios.get('api/notes/'+props.accountID)
            .then(res => {
                if (res.data.length>0) {
                    setAccountNotes(res.data);
                } else {
                    let newItems = [{"note":"No Notes Entered Yet."}];
                    setAccountNotes(newItems);
                }
            }).catch(error => {
            console.log(error.message);
        })
    }

    function formatPhoneNumber (str) {
        //Filter only numbers from the input
        let cleaned = ('' + str).replace(/\D/g, '');

        //Check if the input is of correct length
        let match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);

        if (match) {
            return '(' + match[1] + ') ' + match[2] + '-' + match[3]
        };

        return null
    };


    let accountNotesComponent = null;

    if (accountNotes.length!=0)
        accountNotesComponent = (
        <>
            <div><strong>Notes:</strong><br />
                {accountNotes.map((note, index) => {
                    return <>
                       {note.note}<br />
                    </>
                })}
                </div>
            <br /><strong>Add Note:</strong>
                <form id='addNote' onSubmit={event => {event.preventDefault()}}>
                    <textarea rows="4" cols="50" name="notes" onChange={handleNoteChange}>
                    </textarea>
                 <br />
                    <button className="btn btn-success" onClick={updateNotes} >Save Note</button></form>
        </>
    );


    if (!queueDataComponent && !props.user && !initialMoment) {
        return ( "loading..." )
    } else {
        return (
            <>

                <div className="flex-large" id="mainModal" >

                    <Modal.Header closeButton>
                        <Modal.Title>Account Details - {props.accountID} </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <h2>{props.fullName} - {formatPhoneNumber(props.phone)}</h2>
                        {queueDataHeader}

                        {queueDataComponent}
                    </Modal.Body>
                    <Modal.Footer>
                        <button className="btn btn-success" onClick={updateRecords} >Save Updates</button>
                    </Modal.Footer>

               </div>

                    <Modal show={showCustomActionsModal} onHide={closeCustomActions} className="custom-fields-modal">
                       {/* <div className="flex-large custom-fields-modal" id="CustomActionsModal" >*/}

                        <Modal.Header closeButton>
                        <Modal.Title>Custom Action Fields</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="custom-fields-modal-body">
                        Date of Next Action: <DatetimePickerTrigger
                            moment={initialMoment}
                            onChange={(e) => handleTimePickerChange(e)}
                            showTimePicker={false}
                            minDate={initialMoment}>
                            <input type="text" value={initialMoment.format('MM/DD/YYYY')} readOnly size="10" style={{ textAlign: 'center'}} />
                        </DatetimePickerTrigger>
                        {props.currentTicketID}

                    </Modal.Body>
                    <Modal.Footer>
                        <Button className="btn btn-success"  onClick={handleSave}>Update and Close</Button>
                    </Modal.Footer>
                      {/*  </div>*/}
                    </Modal>


                <Modal show={showAccountNotesModal} onHide={closeNotes} className="custom-fields-modal">
                    {/* <div className="flex-large custom-fields-modal" id="CustomActionsModal" >*/}

                    <Modal.Header closeButton>
                        <Modal.Title>Account Notes</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="custom-fields-modal-body">
                        {accountNotesComponent}
                    </Modal.Body>
                    <Modal.Footer>

                    </Modal.Footer>
                </Modal>

            </>
        )
    }
}

export default ViewQueue