import React, { useState } from 'react';
import axios from 'axios';
import {
    Container, Row, Col, Form, FormControl, InputGroup, Button, Nav, Navbar, NavDropdown, Card
} from 'react-bootstrap';
import { Redirect } from "react-router-dom";
import MaskedInput from 'react-maskedinput'
import './Organization.css';

const Organization = (props) => {


    const [data, setData] = useState(null);
    const [organization, setOrganization] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [updateOrgMessage, setUpdateOrgMessage] = useState(false);

    const handleInputChange = event => {
        const { name, value } = event.target
        setOrganization({ ...organization, [name]: value })
    }

    if (!organization && props.user) {
        props.toggleLoading(false);
        axios.get('api/organization/'+props.user.organization_id)
            .then(res => {
                setOrganization(res.data);
            })
    }


    const updateOrganization = organization => {
        organization.phone = organization.phone.replace(/\D+/g, '')

        let data = {
            name: organization.name,
            address1: organization.address1,
            address2: organization.address2,
            city: organization.city,
            state: organization.state,
            zip: organization.zip,
            phone: organization.phone
        }
        axios.put('api/organization/'+organization.id, data)
            .then(res => {
                setUpdateOrgMessage('Your Organization has been updated');
                setTimeout(() => {  setUpdateOrgMessage(null) }, 5000);
            });


    }

    if (!organization) {
        return (
            <div>Loading...</div>
    );
    } else {
    return (

        <Container>
        <Row>
        <Col>
        <Card className="content-card organization-card">
        <Card.Header>Your Organization</Card.Header>
        <Card.Body>
        <div className="flex-large">

        <form id='addOrganizationForm'
    onSubmit={event => {
        event.preventDefault()
        if (!organization.name || !organization.address1) return
        updateOrganization(organization)
        {/*setOrganization(initialFormState)*/}
    }}
>
    <div id="updateOrgMessage" style={{marginTop: 10 + 'px', marginBottom: 10 + 'px', color: 'green', textAlign: 'center'}} >{ updateOrgMessage }</div>
    <Row>
    <Form.Label column sm="4" className="text-right">Organization Name</Form.Label>
    <Col sm="8" className="text-left"><input type="text" name="name" value={organization.name} onChange={handleInputChange} /></Col>
    </Row>
    <Row>
    <Form.Label column sm="4" className="text-right">Address 1</Form.Label>
    <Col sm="8" className="text-left"><input type="text" name="address1" value={organization.address1}   onChange={handleInputChange} /></Col>
    </Row>
    <Row>
    <Form.Label column sm="4" className="text-right">Address 2</Form.Label>
    <Col sm="8" className="text-left"><input type="text" name="address2" value={organization.address2} onChange={handleInputChange} /></Col>
    </Row>
    <Row>
    <Form.Label column sm="4" className="text-right">City</Form.Label>
        <Col sm="8" className="text-left"><input type="text" name="city" value={organization.city}   onChange={handleInputChange} /></Col>
    </Row>
    <Row>
    <Form.Label column sm="4" className="text-right">State</Form.Label>
        <Col sm="8" className="text-left"><input type="text" name="state" value={organization.state} onChange={handleInputChange} /></Col>
    </Row>
    <Row>
    <Form.Label column sm="4" className="text-right">Zip</Form.Label>
        <Col sm="8" className="text-left"><input type="text" name="zip" value={organization.zip} onChange={handleInputChange} /></Col>
    </Row>
    <Row>
    <Form.Label column sm="4" className="text-right">Phone</Form.Label>
        <Col sm="8" className="text-left">
        <MaskedInput mask="(111) 111-1111" name="phone" size="20" value={organization.phone} onChange={handleInputChange} />
    </Col>
    </Row>
    <Row><Col>
    <button className="btn btn-success" >Update organization</button>
    </Col></Row>
    </form>
    </div>

        </Card.Body>
        </Card>
        </Col>
        </Row>
        </Container>

)}
}

export default Organization;