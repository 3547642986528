import React, { useState,  useEffect, useRef, Suspense, useCallback  } from 'react';
import styled from 'styled-components'
import { useTable, useFilters, useGlobalFilter, useRowSelect, useSortBy, usePagination, } from 'react-table'
import * as Icon from 'react-bootstrap-icons';
import matchSorter from 'match-sorter'
import moment from 'moment'


const Styles = styled.div`
  padding: 1rem;

  table {
    border-spacing: 0;
    border: 1px solid black;

    tr {
      :last-child {
        td {
          border-bottom: 0;
        }
      }
    }

    th,
    td {
      margin: 0;
      padding: 0.5rem;
      border-bottom: 1px solid black;
      border-right: 1px solid black;

      :last-child {
        border-right: 0;
      }
    }
  }
`


    const IndeterminateCheckbox = React.forwardRef(
      ({ indeterminate, ...rest }, ref) => {
        const defaultRef = React.useRef()
        const resolvedRef = ref || defaultRef

        React.useEffect(() => {
          resolvedRef.current.indeterminate = indeterminate
        }, [resolvedRef, indeterminate])

        return (
          <>
            <input type="checkbox" ref={resolvedRef} {...rest} />
          </>
        )
      }
    )


const RecordsTable = (props) => {
  const skipPageResetRef = React.useRef()
  skipPageResetRef.current = props.updateTable;
    // Define a default UI for filtering
    function GlobalFilter({
                              preGlobalFilteredRows,
                              globalFilter,
                              setGlobalFilter,
                          }) {
        const count = preGlobalFilteredRows.length

        return (
            <span>
            Search:{' '}
    <input
        value={globalFilter || ''}
        onChange={e => {
            setGlobalFilter(e.target.value || undefined) // Set undefined to remove the filter entirely
        }}
        placeholder={`${count} records...`}
        style={{
            fontSize: '1.1rem',
                border: '0',
        }}
        />
        </span>
    )
    }



    // Define a default UI for filtering
    function DefaultColumnFilter({
                                     column: { filterValue, preFilteredRows, setFilter },
                                 }) {
        const count = preFilteredRows.length

        return (
            <input
        value={filterValue || ''}
        onChange={e => {
            setFilter(e.target.value || undefined) // Set undefined to remove the filter entirely
        }}
        placeholder={`Search ${count} records...`}
        />
    )
    }

    // This is a custom filter UI for selecting
// a unique option from a list
    function SelectColumnFilter({
                                    column: { filterValue, setFilter, preFilteredRows, id },
                                }) {
        // Calculate the options for filtering
        // using the preFilteredRows
        const options = React.useMemo(() => {
            const options = new Set()
            preFilteredRows.forEach(row => {
                options.add(row.values[id])
            })
            return [...options.values()]
        }, [id, preFilteredRows])

        // Render a multi-select box
        return (
            <select
        value={filterValue}
        onChange={e => {
            setFilter(e.target.value || undefined)
        }}
    >
    <option value="">All</option>
        {options.map((option, i) => (
            <option key={i} value={option}>
            {option}
            </option>
        ))}
    </select>
    )
    }

// This is a custom filter UI that uses a
// slider to set the filter value between a column's
// min and max values
    function SliderColumnFilter({
                                    column: { filterValue, setFilter, preFilteredRows, id },
                                }) {
        // Calculate the min and max
        // using the preFilteredRows

        const [min, max] = React.useMemo(() => {
            let min = preFilteredRows.length ? preFilteredRows[0].values[id] : 0
            let max = preFilteredRows.length ? preFilteredRows[0].values[id] : 0
            preFilteredRows.forEach(row => {
                min = Math.min(row.values[id], min)
                max = Math.max(row.values[id], max)
            })
            return [min, max]
        }, [id, preFilteredRows])

        return (
            <>
            <input
        type="range"
        min={min}
        max={max}
        value={filterValue || min}
        onChange={e => {
            setFilter(parseInt(e.target.value, 10))
        }}
        />
        <button onClick={() => setFilter(undefined)}>Off</button>
        </>
    )
    }

// This is a custom UI for our 'between' or number range
// filter. It uses two number boxes and filters rows to
// ones that have values between the two
    function NumberRangeColumnFilter({
                                         column: { filterValue = [], preFilteredRows, setFilter, id },
                                     }) {
        const [min, max] = React.useMemo(() => {
            let min = preFilteredRows.length ? preFilteredRows[0].values[id] : 0
            let max = preFilteredRows.length ? preFilteredRows[0].values[id] : 0
            preFilteredRows.forEach(row => {
                min = Math.min(row.values[id], min)
                max = Math.max(row.values[id], max)
            })
            return [min, max]
        }, [id, preFilteredRows])

        return (
            <div
        style={{
            display: 'flex',
        }}
    >
    <input
        value={filterValue[0] || ''}
        type="number"
        onChange={e => {
            const val = e.target.value
            setFilter((old = []) => [val ? parseInt(val, 10) : undefined, old[1]])
        }}
        placeholder={`Min (${min})`}
        style={{
            width: '70px',
                marginRight: '0.5rem',
        }}
        />
        to
        <input
        value={filterValue[1] || ''}
        type="number"
        onChange={e => {
            const val = e.target.value
            setFilter((old = []) => [old[0], val ? parseInt(val, 10) : undefined])
        }}
        placeholder={`Max (${max})`}
        style={{
            width: '70px',
                marginLeft: '0.5rem',
        }}
        />
        </div>
    )
    }


    function fuzzyTextFilterFn(rows, id, filterValue) {
        return matchSorter(rows, filterValue, { keys: [row => row.values[id]] })
    }

    // Let the table remove the filter if the string is empty
    fuzzyTextFilterFn.autoRemove = val => !val

    const columns = React.useMemo(() => [
    // const columns = [

            {
                id: "selection",
                accessor: "id",
                disableSortBy: true,
                disableFilters: true,
                // The header can use the table's getToggleAllRowsSelectedProps method
                // to render a checkbox
                Header: ({ getToggleAllRowsSelectedProps }) => (
                    <div>
                        <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} onClick={(e)=>{props.handleAllRowsClick(e,getToggleAllRowsSelectedProps())}} />
                    </div>
        ),
        // The cell can use the individual row's getToggleRowSelectedProps method
        // to the render a checkbox

        Cell: ({ row }) => (
            <div>
                <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} onClick={(e)=>{props.handleCheckboxClick(e,row)}} />
            </div>
        )
    },
    {
        Header: '',
        id: 10,
        Cell: row => (
        <div>
        <Icon.TextLeft color="black" size={32} onClick={() => props.showViewLog(row.row.original.id)} />
        </div>
        )
    },
    { Header: 'Account ID', accessor: 'procedure_task.org_account_id', },

    { Header: 'Queue',
        accessor: d => {
        if (d.ticket_queue_id) {
            return props.queues[d.ticket_queue_id].name;
        } else {
            return "Unassigned";
        }
    },
    Filter: SelectColumnFilter, filter: 'includes', },
    { Header: 'Diagnosis Date', accessor: d => {
        if (d.procedure_task.diagnosis_date) {
            return moment(d.procedure_task.diagnosis_date)
                .local()
                .format("MM/DD/YYYY")
        } else {
            return "None Recorded"
        }   }},
    { Header: 'Patient Code', accessor: 'procedure_task.patient_code', Filter: SelectColumnFilter, filter: 'includes', },
    { Header: 'Procedure Code', accessor: 'procedure_task.procedure_code', Filter: SelectColumnFilter, filter: 'includes', },
    { Header: 'Procedure Description', accessor: 'procedure_task.procedure_description', Filter: SelectColumnFilter, filter: 'includes', },
    { Header: 'Tooth', accessor: 'procedure_task.tooth', },
    { Header: 'Treatment Scheduled', accessor: 'procedure_task.treatment_scheduled', },
    { Header: 'Amount', accessor: 'procedure_task.amount',  Filter: SliderColumnFilter, filter: filterGreaterThan,},
    { Header: 'Doctor', accessor: 'procedure_task.doctor_full_name',  Filter: SelectColumnFilter, filter: 'includes', },
    { Header: 'Next Action At', accessor: d => {
        if (d.next_action_at) {
            return moment(d.next_action_at)
                .local()
                .format("MM/DD/YYYY hh:mm:ss a")
        } else {
            return "None Recorded"
        }
    }} ,
    { Header: 'Last Action At', accessor: d => {
            if (d.last_action_at) {
                return moment(d.last_action_at)
                    .local()
                    .format("MM/DD/YYYY hh:mm:ss a")
            } else {
                return "None Recorded"
            }
        }}
    //{ Header: 'Call Attempts', accessor: 'procedure_task.call_attemtps', },

    //{ Header: 'Status', accessor: 'status.name', Filter: SelectColumnFilter, filter: 'includes', },


    ],
    [props.queues, props.data]
  )

    function Table({ columns, data }) {
        // Use the state and functions returned from useTable to build your UI


        const filterTypes = React.useMemo(
            () => ({
                // Add a new fuzzyTextFilterFn filter type.
                fuzzyText: fuzzyTextFilterFn,
                // Or, override the default text filter to use
                // "startWith"
                text: (rows, id, filterValue) => {
                    return rows.filter(row => {
                        const rowValue = row.values[id]
                        return rowValue !== undefined
                            ? String(rowValue)
                                .toLowerCase()
                                .startsWith(String(filterValue).toLowerCase())
                            : true
                    })
                },
            }),
            []
        )

        const defaultColumn = React.useMemo(
            () => ({
                // Let's set up our default Filter UI
                Filter: DefaultColumnFilter,
            }),
            []
        )

        const {
            getTableProps,
            getTableBodyProps,
            headerGroups,
            prepareRow,
            flatColumns,
            preGlobalFilteredRows,
            setGlobalFilter,
            selectedFlatRows,
            page,
            canPreviousPage,
            canNextPage,
            pageOptions,
            pageCount,
            gotoPage,
            nextPage,
            previousPage,
            setPageSize,
            state: { selectedRowIds, pageIndex, pageSize },
        }  = useTable(
            {
                columns,
                data,
                defaultColumn, // Be sure to pass the defaultColumn option
                filterTypes,
                initialState: { pageIndex: 0 }, // Pass our hoisted table state
                autoResetPage: !skipPageResetRef.current,
                autoResetExpanded: !skipPageResetRef.current,
                autoResetGroupBy: !skipPageResetRef.current,
                autoResetSelectedRows: false,
                autoResetSortBy: !skipPageResetRef.current,
                autoResetFilters: !skipPageResetRef.current,
                autoResetRowState: !skipPageResetRef.current,
            },

            useFilters, // useFilters!
            useGlobalFilter, // useGlobalFilter!
            useSortBy,
            usePagination,
            useRowSelect,
          
        )
        // Render the UI for your table


        // Listen for changes in pagination and use the state to fetch our new data
        /*React.useEffect(() => {
            fetchData({ pageIndex, pageSize })
        }, [fetchData, pageIndex, pageSize])
*/

        return (
            <>
            <table style={{width: '100%'}} {...getTableProps()}>
    <thead>
        {headerGroups.map(headerGroup => (
                <tr {...headerGroup.getHeaderGroupProps()}>
        {headerGroup.headers.map(column => (
            <th {...column.getHeaderProps()}>
            {/* Render the columns filter UI */}
            <div> <span {...column.getSortByToggleProps()}>
            {column.render('Header')}
            {/* Add a sort direction indicator */}
            {column.isSorted
                ? column.isSortedDesc
                    ? ' 🔽'
                    : ' 🔼'
                : ''}
        </span></div>
        <div>{column.canFilter ? column.render('Filter') : null}
        </div>
        </th>
        ))}
    </tr>
    ))}
    <tr>
        <th
        colSpan={flatColumns.length}
        style={{
            textAlign: 'left',
        }}
    >
        </th>
        </tr>
    </thead>
        <tbody {...getTableBodyProps()}>
        {page.map(
            (row, i) => {
                prepareRow(row);
                return (
                    <tr {...row.getRowProps()}>
                {row.cells.map(cell => {
                    return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                })}
            </tr>
            )}
        )}
    </tbody>
        </table>
        {/*
        Pagination can be built however you'd like.
        This is just a very basic UI implementation:
      */}
    <div className="pagination">
            <button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
        {'<<'}
    </button>{' '}
        <button onClick={() => previousPage()} disabled={!canPreviousPage}>
        {'<'}
    </button>{' '}
        <button onClick={() => nextPage()} disabled={!canNextPage}>
        {'>'}
    </button>{' '}
        <button onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
        {'>>'}
    </button>{' '}
        <span>
        Page{' '}
    <strong>
        {pageIndex + 1} of {pageOptions.length}
    </strong>{' '}
        </span>
        <span>
        | Go to page:{' '}
    <input
        type="number"
        defaultValue={pageIndex + 1}
        onChange={e => {
            const page = e.target.value ? Number(e.target.value) - 1 : 0
            gotoPage(page)
        }}
        style={{ width: '100px' }}
        />
        </span>{' '}
        <select
        value={pageSize}
        onChange={e => {
            setPageSize(Number(e.target.value))
        }}
    >
        {[10, 20, 30, 40, 50].map(pageSize => (
            <option key={pageSize} value={pageSize}>
            Show {pageSize}
        </option>
        ))}
    </select>
        </div>
        </>
    )
    }

    // Define a custom filter filter function!
    function filterGreaterThan(rows, id, filterValue) {
        return rows.filter(row => {
            const rowValue = row.values[id]
            return rowValue >= filterValue
        })
    }


    filterGreaterThan.autoRemove = val => typeof val !== 'number'


    return (
    <Styles>
      <Table
        columns={columns}
        data={props.data}
      />
    </Styles>
    )

}

export default RecordsTable;