import React, { useState } from 'react';
import axios from 'axios';
import {
    Container, Modal, Row, Col, Form, FormControl, InputGroup, Button, Nav, Navbar, NavDropdown, Card
} from 'react-bootstrap';
import { Redirect } from "react-router-dom";
import './Reports.css';
import * as Icon from 'react-bootstrap-icons';
import ViewUserLog from './ViewUserLog'



const Reports = (props) => {


    const [showUserLog, setShowUserLog] = useState(false);
    const handleCloseUserLog = () => setShowUserLog(false);
    const handleShowUserLog = () => setShowUserLog(true);

    return (
        <>
            <Container>
                <Row>
                    <Col>
                        <Card className="report-card content-card">
                            <Card.Header>Reports</Card.Header>
                            <Card.Body>
                                <Icon.TextLeft color="black" size={64} onClick={() => setShowUserLog(true)} />
                                <div onClick={() => setShowUserLog(true)}><h3>User Activity Log</h3></div>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
            <Modal show={showUserLog} onHide={handleCloseUserLog} dialogClassName="view-user-log-modal">
                <ViewUserLog  {...props} />
            </Modal>
        </>
    )
}

export default Reports;