import React, { useState } from 'react';
import axios from 'axios';
import styled from 'styled-components'
import {
    Container, Row, Col, Button, Card, Modal
} from 'react-bootstrap';
import { useTable } from 'react-table'
import * as Icon from 'react-bootstrap-icons';
import AddOrganizationForm from './AddOrganizationForm'
import EditOrganizationForm from './EditOrganizationForm'
import './Organizations.css';
import ReactTable from 'react-table-6'
import 'react-table-6/react-table.css'

const Styles = styled.div`
  padding: 1rem;

  table {
    border-spacing: 0;
    border: 1px solid black;

    tr {
      :last-child {
        td {
          border-bottom: 0;
        }
      }
    }

    th,
    td {
      margin: 0;
      padding: 0.5rem;
      border-bottom: 1px solid black;
      border-right: 1px solid black;

      :last-child {
        border-right: 0;
      }
    }
  }
`

const Organizations = (props) => {

    const [data, setData] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [editing, setEditing] = useState(false)
    const initialFormState = { id: null, name: '', address1: '', address2: '', city: '', state: '', zip: '',  phone: '',  owner_name: '',  owner_email: '' }
    const [organizations, setOrganizations] = useState(null);
    const [currentOrganization, setCurrentOrganization] = useState(initialFormState);
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const handleEditClose = () => setEditShow(false);
    const handleEditShow = () => setEditShow(true);
    const [editShow, setEditShow] = useState(false);

    if ((!data) && (props.user)) {
        props.toggleLoading(true);
        axios.get('api/organizations')
            .then(res => {
                props.toggleLoading(false);
                setData(res.data);
            })
    }

    function handleEdit({ row }) {
        setEditing(true)
        setCurrentOrganization({
            id: row.id,
            name: row.name,
            address1: row.address1,
            address2: row.address2,
            city: row.city,
            state: row.state,
            zip: row.zip,
            phone: row.phone,
            owner_name: row.owner_name,
            owner_email: row.owner_email,
        })
        let data = {
            name: row.name,
            address1: row.address1,
            address2: row.address2,
            city: row.city,
            state: row.state,
            zip: row.zip,
            phone: row.phone,
            owner_name: row.owner_name,
            owner_email: row.owner_email,
        }

        handleEditShow();
    }

    // CRUD operations
    const addOrganization = organization => {
        organization.phone = organization.phone.replace(/\D+/g, '')

        let data = {
            name: organization.name,
            address1: organization.address1,
            address2: organization.address2,
            city: organization.city,
            state: organization.state,
            zip: organization.zip,
            phone: organization.phone,
            owner_name: organization.owner_name,
            owner_email: organization.owner_email,
        }
        axios.post('api/organization', data)
            .then(res => {
                axios.get('api/organizations')
                    .then(res => {
                        setShow(false);
                        setData(null);
                        setData(res.data);
                    })
            });


    }

    const updateOrganization = (id, updatedOrganization) => {
        updatedOrganization.phone = updatedOrganization.phone.replace(/\D+/g, '')
        setEditing(false)
        let data = {
            name: updatedOrganization.name,
            address1: updatedOrganization.address1,
            address2: updatedOrganization.address2,
            city: updatedOrganization.city,
            state: updatedOrganization.state,
            zip: updatedOrganization.zip,
            phone: updatedOrganization.phone
        }

        axios.put('api/organization/'+updatedOrganization.id, data)
            .then(res => {
                axios.get('api/organizations')
                    .then(res => {
                        setEditShow(false);
                        setData(null);
                        setData(res.data);
                    })
            });
    }


    /*
        const deleteOrganization = id => {
            setEditing(false)
            setOrganizations(organizations.filter(organization => organization.id !== id))
        }

        const updateOrganization = (id, updatedOrganization) => {
            setEditing(false)
            setOrganizations(organizations.map(organization => (organization.id === id ? updatedOrganization : organization)))
        }

        const editRow = organization => {
            setEditing(true)
            setCurrentOrganization({ id: organization.id, name: organization.name, address1: organization.address1, address2: organization.address2, city: organization.city, state: organization.state, zip: organization.zip })
        }*/

    function handleDelete({ row }) {
        axios.delete('api/organization/' +  row.id)
            .then(res => {
                axios.get('api/organizations')
                    .then(res => {
                        setData(null);
                        setData(res.data);
                    })
            })
            .catch((err) => {
                console.log(err);
            })
    }

    function formatPhoneNumber (str) {
        //Filter only numbers from the input
        let cleaned = ('' + str).replace(/\D/g, '');

        //Check if the input is of correct length
        let match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);

        if (match) {
            return '(' + match[1] + ') ' + match[2] + '-' + match[3]
        };

        return null
    };

    const columns = React.useMemo(
        () => [
            {
                Header: '',
                id: 1,
                Cell: row => (
                    <div>
                    <Icon.Pencil color="royalblue" size={32} onClick={() => handleEdit(row)} />
                    <Icon.XCircle color="red" size={32} onClick={() => handleDelete(row)} />
                    </div>
            )
            },  {
                Header: 'Name',
                accessor: 'name',
            },
            {
                Header: 'id',
                accessor: 'id',
                show: false,
            },
            {
                Header: 'Address',
                columns: [
                    {
                        Header: 'Address1',
                        accessor: 'address1',
                    },
                    {
                        Header: 'Address2',
                        accessor: 'address2',
                    },
                    {
                        Header: 'City',
                        accessor: 'city',
                    },
                    {
                        Header: 'State',
                        accessor: 'state',
                        maxWidth: 50,
                    },
                    {
                        Header: 'Zip',
                        accessor: 'zip',
                        maxWidth: 80,
                    },
                ],
            },
             {
                Header: 'Phone',
                 accessor: 'phone',
                 Cell: props => {
                 return formatPhoneNumber(props.value)
             },
                 }
        ],
        []
    )


    function Table({ columns, data }) {
        // Use the state and functions returned from useTable to build your UI
        const {
            getTableProps,
            getTableBodyProps,
            headerGroups,
            rows,
            prepareRow,
        } = useTable({
            columns,
            data,
        })
        // Render the UI for your table
        return (
            <table style={{width: '100%'}} {...getTableProps()}>
    <thead>
        {headerGroups.map(headerGroup => (
                <tr {...headerGroup.getHeaderGroupProps()}>
        {headerGroup.headers.map(column => (
            <th {...column.getHeaderProps()}>{column.render('Header')}</th>
        ))}
    </tr>
    ))}
    </thead>
        <tbody {...getTableBodyProps()}>
        {rows.map(
            (row, i) => {
                prepareRow(row);
                return (
                    <tr {...row.getRowProps()}>
                {row.cells.map(cell => {
                    return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                })}
            </tr>
            )}
        )}
    </tbody>
        </table>
    )
    }

    if (!data) {
        return (
            <div>Loading...</div>
    );
    } else {
        return (
            <Container>
            <Row>
            <Col>
            <Card className="content-card">
            <Card.Header>Organizations</Card.Header>
            <Card.Body>
            <Row><Col className="text-right">
            <Button className={['btn-success']} variant="primary" onClick={handleShow}  style={{marginRight: '15px', marginBottom: '10px'}}>
            Add an Organization
        </Button>
            </Col></Row>
            {/*<Styles>
            <Table columns={columns} data={data} />
            </Styles>*/}
                <ReactTable
                data={data}
                columns={columns}
                defaultPageSize={10}
            />
        <Modal show={show} onHide={handleClose}>
        <AddOrganizationForm addOrganization={addOrganization} />
        </Modal>
        <Modal show={editShow} onHide={handleEditClose}>
            <EditOrganizationForm handleEditClose={handleEditClose} updateOrganization={updateOrganization} currentOrganization={currentOrganization}  handleEdit={handleEdit} />
        </Modal>

        </Card.Body>
        </Card>
        </Col>
        </Row>
        </Container>

    );
    }
}

export default Organizations;