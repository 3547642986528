import React, { useState } from 'react'
import {
    Container, Row, Col, Form, FormControl, InputGroup, Button, Nav, Navbar, NavDropdown, Card, Modal
} from 'react-bootstrap';
import MaskedInput from 'react-maskedinput'

const AddOrganizationForm = props => {

    const initialFormState = { id: null, name: '', address1: '', address2: '', city: '', state: '', zip: '', phone: '', owner_name: '', owner_email: '', }
    const [organization, setOrganization] = useState(initialFormState);

    const handleInputChange = event => {
        const { name, value } = event.target
        setOrganization({ ...organization, [name]: value })
    }

    return (
        <>
        <div className="flex-large">

        <form id='addOrganizationForm'
    onSubmit={event => {
        event.preventDefault()
        if (!organization.name || !organization.address1) return
        props.addOrganization(organization)
        setOrganization(initialFormState)
    }}
>
        <Modal.Header closeButton>
        <Modal.Title>Add an Organization</Modal.Title>
        </Modal.Header>
        <Modal.Body>


<Row>
    <Form.Label column sm="4" className="text-right">Organization Name</Form.Label>
    <Col sm="8" className="text-left"><input type="text" name="name" value={organization.name} onChange={handleInputChange} /></Col>
    </Row>
    <Row>
        <Form.Label column sm="4" className="text-right">Address 1</Form.Label>
        <Col sm="8" className="text-left"><input type="text" name="address1" value={organization.address1}   onChange={handleInputChange} /></Col>
    </Row>
    <Row>
        <Form.Label column sm="4" className="text-right">Address 2</Form.Label>
        <Col sm="8" className="text-left"><input type="text" name="address2" value={organization.address2} onChange={handleInputChange} /></Col>
    </Row>
    <Row>
        <Form.Label column sm="4" className="text-right">City</Form.Label>
        <Col sm="8" className="text-left"><input type="text" name="city" value={organization.city}   onChange={handleInputChange} /></Col>
    </Row>
    <Row>
        <Form.Label column sm="4" className="text-right">State</Form.Label>
        <Col sm="8" className="text-left"><input type="text" name="state" value={organization.state} onChange={handleInputChange} /></Col>
    </Row>
    <Row>
        <Form.Label column sm="4" className="text-right">Zip</Form.Label>
        <Col sm="8" className="text-left"><input type="text" name="zip" value={organization.zip} onChange={handleInputChange} /></Col>
    </Row>
    <Row>
    <Form.Label column sm="4" className="text-right">Phone</Form.Label>
        <Col sm="8" className="text-left">
        <MaskedInput mask="(111) 111-1111" name="phone" size="20" onChange={handleInputChange} />
    </Col>
    </Row>
    <Row>
    <Form.Label column sm="4" className="text-right">Owner's Name</Form.Label>
        <Col sm="8" className="text-left"><input type="text" name="owner_name" value={organization.owner_name} onChange={handleInputChange} /></Col>
    </Row>
    <Row>
    <Form.Label column sm="4" className="text-right">Owner's Email Address</Form.Label>
        <Col sm="8" className="text-left"><input type="text" name="owner_email" value={organization.owner_email} onChange={handleInputChange} /></Col>
    </Row>
    </Modal.Body>
    <Modal.Footer>
    <button className="btn btn-success" >Add new organization</button>
        </Modal.Footer>
    </form>
    </div>
    </>
)
}

export default AddOrganizationForm